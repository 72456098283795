import { createSlice, createAsyncThunk, AsyncThunk } from '@reduxjs/toolkit';
import {
  CreateInviteRequest,
  Invite,
  ResendInviteRequest,
} from '../../@models/invite';
import {
  createInviteMutation,
  getInvitesQuery,
  resendInviteMutation,
} from '../../providers/invites';
import { LoadingStatus } from '../common';

type GetInvitesProps = {
  orgId: string;
  searchString: string;
};
type GetInvitesResponse = {
  data: Invite[];
  meta: any;
};
type GetInvites = AsyncThunk<GetInvitesResponse, GetInvitesProps, any>;

type CreateInviteProps = CreateInviteRequest;
type CreateInvitesResponse = {
  data: Invite;
  meta: any;
};
type CreateInvite = AsyncThunk<CreateInvitesResponse, CreateInviteProps, any>;

type ResendInviteProps = ResendInviteRequest;
type ResendInviteResponse = {
  data: any;
  meta: any;
};
type ResendInvite = AsyncThunk<ResendInviteResponse, ResendInviteProps, any>;

function MapResponseToInvite(responseObject: any): Invite {
  return {
    name: responseObject.forenames,
    surname: responseObject.lastName,
    email: responseObject.inviteEmail,
    orgId: responseObject.orgId,
    id: responseObject.id,
    role: responseObject.role,
    createdAt: responseObject.createdAt,
    inviter: responseObject.inviter,
    response: 'Not responded',
  };
}

export const getInvites = <GetInvites>(
  createAsyncThunk(
    'invites/setInvites',
    async ({ orgId, searchString }, { rejectWithValue }) => {
      try {
        const response = await getInvitesQuery({
          orgId: orgId,
          searchString: searchString,
        });

        response.data = response.data.map(MapResponseToInvite);

        return response;
      } catch (err) {
        console.warn(err);
        // If it fails, set user without id and throw error
        return rejectWithValue({ error: err });
      }
    }
  )
);

export const createInvite = <CreateInvite>(
  createAsyncThunk(
    'invites/createInvite',
    async (request: CreateInviteProps, { rejectWithValue }) => {
      try {
        const response = await createInviteMutation(request);
        const result = {
          data: MapResponseToInvite(response.data),
          meta: {},
        };

        return result;
      } catch (err: any) {
        console.warn(err);
        return rejectWithValue({ error: err });
      }
    }
  )
);

export const resendInvite = <ResendInvite>(
  createAsyncThunk(
    'invites/resendInvite',
    async (request: ResendInviteProps, { rejectWithValue }) => {
      try {
        await resendInviteMutation(request);
        const result = {
          data: {},
          meta: {},
        };

        return result;
      } catch (err: any) {
        console.warn(err);
        return rejectWithValue(err.response.data);
      }
    }
  )
);

const initialState = {
  invites: <Invite[]>[],
  status: LoadingStatus.idle,
  error: <string>'',
};

const invitesSlice = createSlice({
  name: 'invites',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getInvites.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(getInvites.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        invites: [...action.payload.data],
      };
    });

    builder.addCase(getInvites.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
        invites: [],
      };
    });
    builder.addCase(createInvite.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(createInvite.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        invites: [...state.invites, action.payload.data],
      };
    });
    builder.addCase(createInvite.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
      };
    });
    builder.addCase(resendInvite.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(resendInvite.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        invites: [],
      };
    });
    builder.addCase(resendInvite.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
      };
    });
  },
});

export default invitesSlice.reducer;
