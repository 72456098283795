import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { importAccredsUsersCsvMutation } from '../../providers/accredsUsers';
import { LoadingStatus } from '../common';
import { Storage } from 'aws-amplify';
import { nanoid } from 'nanoid';

type ImportAccredsUsersCsvResponse = {
  meta: any;
};

type ImportAccredsUsersCsvProps = {
  orgId: string;
  file: File;
};

type ImportAccredsUsersCsv = AsyncThunk<
  ImportAccredsUsersCsvResponse,
  ImportAccredsUsersCsvProps,
  any
>;

export const importAccredsUsersCsv = <ImportAccredsUsersCsv>(
  createAsyncThunk(
    'accreds-users/importCsv',
    async (request, { rejectWithValue }) => {
      var importId = nanoid();

      try {
        await Storage.put(
          `${request.orgId}/uploads/${importId}.csv`,
          request.file,
          {
            contentType: 'text/csv',
            level: 'public',
          }
        );
      } catch (err: any) {
        console.warn(err);
        return rejectWithValue({
          error: err,
        });
      }

      try {
        await importAccredsUsersCsvMutation(request.orgId, importId);
      } catch (err) {
        console.warn(err);
        return rejectWithValue({ error: err });
      }
    }
  )
);

const initialState = {
  status: LoadingStatus.idle,
  error: <string>'',
};

const accredsUsersCsvFileSlice = createSlice({
  name: 'accredsUsersCsvFile',
  initialState,
  reducers: {
    clearUploadAccredsUsersCsv: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(importAccredsUsersCsv.pending, (state) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });

    builder.addCase(importAccredsUsersCsv.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
      };
    });

    builder.addCase(importAccredsUsersCsv.rejected, (state, action) => {
      // @ts-ignore
      return {
        ...state,
        status: LoadingStatus.failed,
        error: 'Failed to import accreditation users',
      };
    });
  },
});

export const { clearUploadAccredsUsersCsv } = accredsUsersCsvFileSlice.actions;
export default accredsUsersCsvFileSlice.reducer;
