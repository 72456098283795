import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DailyChallengeCompletionRates } from '../../@models/dailyChallengeCompletionRates';
import { getDailyChallengeCompletionRates } from '../../providers/dailyChallengeCompletionRates';
import { LoadingStatus } from '../common';

type DailyChallengeCompletionProps = {
  orgId: string;
  timeRange: string;
};

type DailyChallengeCompletionRatesResponse = DailyChallengeCompletionRates;

type DailyChallengeCompletion = AsyncThunk<
  DailyChallengeCompletionRatesResponse,
  DailyChallengeCompletionProps,
  any
>;

export const getDailyChallengeCompletion = <DailyChallengeCompletion>(
  createAsyncThunk(
    'challenges/daily-completion',
    async (request, { rejectWithValue }) => {
      try {
        const getDailyChallengeCompletionRatesResponse =
          await getDailyChallengeCompletionRates(
            request.orgId,
            request.timeRange
          );

        return getDailyChallengeCompletionRatesResponse;
      } catch (err) {
        console.warn(err);
        return rejectWithValue({ error: err });
      }
    }
  )
);

const initialState = {
  dailyChallengeCompletion: <DailyChallengeCompletionRates>{},
  status: LoadingStatus.idle,
  error: <string>'',
};

const dailyChallengeCompletionSlice = createSlice({
  name: 'dailyChallengeCompletion',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDailyChallengeCompletion.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(getDailyChallengeCompletion.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        dailyChallengeCompletion: { ...action.payload },
      };
    });

    builder.addCase(getDailyChallengeCompletion.rejected, (state, action) => {
      // @ts-ignore
      return {
        ...state,
        status: LoadingStatus.failed,
        error: 'Failed to get daily challenge completion rates',
      };
    });
  },
});

export default dailyChallengeCompletionSlice.reducer;
