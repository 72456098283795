import Api, { AxiosPromise } from './axioDefault';

type ImportMixedTasksCsvRequest = {
  name: string;
  orgId: string;
  headline: string;
  cadence: string;
  taxonomy?: string;
  visibility?: string;
  contentCsvImportId: string;
  requiredReviewCount: number;
  reviewWindowInHours: number;
  reviewType: string;
};

export async function importMixedTasksCsvMutation(
  request: ImportMixedTasksCsvRequest
): AxiosPromise {
  return await Api.post(
    `task-specs/v1/import-mixed-task-spec-from-csv`,
    request
  );
}
