import { Route, Redirect, RouteProps } from 'react-router-dom';
import { OrganisationUserRole } from '../@models/user';
import { useDispatch, useSelector } from '../state/store';
import { setCurrentOrgId } from '../state/user/userSlice';

type Props = {
  children: any;
  auth?: boolean;
  path: string;
  exact?: boolean;
};

function AuthRoute({ children, auth, ...rest }: Props) {
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const render = ({ location }: RouteProps) => {
    if (!auth) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location },
          }}
        />
      );
    }

    const adminOrgs = user?.orgs?.filter(
      (org) => org.role !== OrganisationUserRole.Member
    );

    // If at this point we don't have any organisations, then we assume we must just download the app
    if (!adminOrgs || adminOrgs.length === 0) {
      return (
        <Redirect
          to={{
            pathname: '/download-app',
            state: { from: location },
          }}
        />
      );
    }

    //if user did not select an org and user has more than one organisation, prompt user to choose one
    if (!user.currentOrgId && adminOrgs.length > 1) {
      return (
        <Redirect
          to={{
            pathname: '/choose-organisation',
            state: { from: location },
          }}
        />
      );
    }

    if (!user.currentOrgId) {
      dispatch(setCurrentOrgId(adminOrgs[0].id));
    }

    return children;
  };

  return <Route {...rest} render={render} />;
}

export default AuthRoute;
