import {
  CreateHierarchyTaskScheduleRequest,
  UpdateHierarchyTaskScheduleRequest,
} from '../@models/taskSchedule';
import Api, { AxiosPromise } from './axioDefault';

async function createHierarchyTaskScheduleMutation(
  request: CreateHierarchyTaskScheduleRequest
): AxiosPromise {
  const body = {
    ...request,
  };

  return await Api.post(
    `/task-specs/v1/${request.taskSpecId}/hierarchy-schedules`,
    body
  );
}

async function updateHierarchyTaskScheduleMutation(
  request: UpdateHierarchyTaskScheduleRequest
): AxiosPromise {
  const body = {
    ...request,
  };

  return await Api.put(
    `/task-specs/v1/${request.taskSpecId}/hierarchy-schedules/${request.id}`,
    body
  );
}

export {
  createHierarchyTaskScheduleMutation,
  updateHierarchyTaskScheduleMutation,
};
