import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Industry } from '../../@models/industry';
import { getIndustriesQuery } from '../../providers/industries';

const initialState = <Industry[]>[];

export const getIndustries = createAsyncThunk(
  'industry/getIndustries',
  async () => {
    try {
      return getIndustriesQuery();
    } catch (err) {
      console.warn(err);
      return [];
    }
  }
);

const industriesSlice = createSlice({
  name: 'industries',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getIndustries.fulfilled, (state, action) => {
        return action.payload;
      })
      .addDefaultCase((state, action) => {});
  },
});

export default industriesSlice.reducer;
