import { ThemeProvider } from '@material-ui/core';
import { Amplify, Auth } from 'aws-amplify';
import React from 'react';
import { Provider } from 'react-redux';
import { IntercomProvider } from 'react-use-intercom';
import { PersistGate } from 'redux-persist/integration/react';
import { visiblyTheme } from './components/@Base/Theme';
import config from './config';
import Routes from './navigation/routes';
import { persistor, store } from './state/store';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

Sentry.init({
  dsn: config.sentry.dsn,
  integrations: [new BrowserTracing()],
  environment: config.sentry.environment,
  release: config.sentry.release,
  attachStacktrace: true,
  enabled: config.sentry.enabled,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  ignoreErrors: [/^ResizeObserver loop limit exceeded$/],
});

Amplify.configure(config.aws);

if (config.aws_endpoint_override && config.aws_endpoint_override.length > 0) {
  Auth.configure({
    endpoint: config.aws_endpoint_override,
  });
}

function App(): React.ReactElement {
  return (
    <IntercomProvider appId={config.intercom.appId}>
      <ThemeProvider theme={visiblyTheme}>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <Routes />
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </IntercomProvider>
  );
}

export default App;
