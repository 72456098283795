import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PublicInvite } from '../../@models/invite';
import { getPublicInviteByIdQuery } from '../../providers/invites';
import { LoadingStatus } from '../common';

type GetPublicInviteByIdProps = { id: string };
type GetPublicInviteByIdResponse = {
  data: PublicInvite;
  meta: any;
};

type GetPublicInviteById = AsyncThunk<
  GetPublicInviteByIdResponse,
  GetPublicInviteByIdProps,
  any
>;

function MapResponseToPublicInvite(
  id: string,
  responseObject: any
): PublicInvite {
  return {
    id,
    role: responseObject.role,
    invitedEmail: responseObject.invitedEmail,
    inviter: responseObject.inviter,
    organisationName: responseObject.organisationName ?? null,
  };
}

export const getPublicInviteById = <GetPublicInviteById>(
  createAsyncThunk(
    'publicInvite/setInvite',
    async ({ id }, { rejectWithValue }) => {
      try {
        const response = await getPublicInviteByIdQuery(id);
        const result = {
          data: MapResponseToPublicInvite(id, response.data),
          meta: {},
        };

        return result;
      } catch (err) {
        console.warn(err);
        return rejectWithValue({ error: err });
      }
    }
  )
);

const initialState = {
  publicInvite: <PublicInvite | null>null,
  status: LoadingStatus.idle,
  error: <string>'',
};

const publicInviteSlice = createSlice({
  name: 'publicInvite',
  initialState,
  reducers: {
    clearPublicInvite: (state) => {
      return {
        ...state,
        publicInvite: null,
        status: LoadingStatus.idle,
        error: <string>'',
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPublicInviteById.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(getPublicInviteById.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        publicInvite: action.payload.data,
      };
    });

    builder.addCase(getPublicInviteById.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
      };
    });
  },
});

export const { clearPublicInvite } = publicInviteSlice.actions;
export default publicInviteSlice.reducer;
