import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TaskSpecUserPerformance } from '../../@models/taskSpecUserPerformance';
import { getTaskSpecUserPerformanceQuery } from '../../providers/challengeStats';
import { LoadingStatus } from '../common';

type GetTaskSpecUserPerformanceResponse = {
  data: TaskSpecUserPerformance[];
  meta: {};
};

type GetTaskSpecUserPerformanceProps = {
  orgId: string;
  timeRange: string;
};

type GetTaskSpecUserPerformance = AsyncThunk<
  GetTaskSpecUserPerformanceResponse,
  GetTaskSpecUserPerformanceProps,
  any
>;

export const getTaskSpecUserPerformance = <GetTaskSpecUserPerformance>(
  createAsyncThunk(
    'stats-challenges/getTaskSpecUserPerformance',
    async (request, { rejectWithValue }) => {
      try {
        return await getTaskSpecUserPerformanceQuery(
          request.orgId,
          request.timeRange
        );
      } catch (err) {
        console.warn(err);
        return rejectWithValue({ error: err });
      }
    }
  )
);

const initialState = {
  taskSpecUserPerformances: <TaskSpecUserPerformance[]>[],
  status: LoadingStatus.idle,
  error: <string>'',
};

const taskSpecUserPerformanceSlice = createSlice({
  name: 'taskSpecUserPerformance',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTaskSpecUserPerformance.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(getTaskSpecUserPerformance.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        taskSpecUserPerformances: [...action.payload.data],
      };
    });

    builder.addCase(getTaskSpecUserPerformance.rejected, (state, action) => {
      // @ts-ignore
      return {
        ...state,
        status: LoadingStatus.failed,
        error: 'Failed to get reviewer activity',
      };
    });
  },
});

export default taskSpecUserPerformanceSlice.reducer;
