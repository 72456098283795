import {
  AddTeamMembersRequest,
  RemoveTeamMembersRequest,
} from '../@models/teamMember';
import Api, { AxiosPromise } from './axioDefault';

async function addTeamMembersMutation(
  request: AddTeamMembersRequest
): AxiosPromise {
  const body = {
    userIds: request.userIds,
  };
  return await Api.post(`/teams/v1/${request.teamId}/members/bulk-add`, body);
}

async function removeTeamMembersMutation(
  request: RemoveTeamMembersRequest
): AxiosPromise {
  const body = {
    userIds: request.userIds,
  };

  return await Api.post(
    `/teams/v1/${request.teamId}/members/bulk-delete`,
    body
  );
}

export { addTeamMembersMutation, removeTeamMembersMutation };
