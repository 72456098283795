import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Organisation } from '../../@models/organisation';
import { getOrganisationsQuery } from '../../providers/organisations';
import { LoadingStatus } from '../common';

type GetOrganisationsProps = {
  searchString: string;
};
type GetOrganisationsResponse = {
  data: Organisation[];
  meta: any;
};

type GetOrganisations = AsyncThunk<
  GetOrganisationsResponse,
  GetOrganisationsProps,
  any
>;

function MapResponseToOrganisation(responseObject: any): Organisation {
  return {
    id: responseObject.id,
    industry: responseObject.industry,
    country: responseObject.country,
    name: responseObject.name,
    verified: responseObject.verified,
    emailVerified: responseObject.emailVerified,
    email: responseObject.email,
    website: '',
    createdDate: responseObject.createdDate,
    featureFlags: responseObject.featureFlags,
  };
}

export const getOrganisations = <GetOrganisations>(
  createAsyncThunk(
    'organisations/getOrganisations',
    async ({ searchString }, { rejectWithValue }) => {
      try {
        const response = await getOrganisationsQuery({
          searchString: searchString,
        });

        const result = {
          data: response.data.map(MapResponseToOrganisation),
          meta: {},
        };

        return result;
      } catch (err) {
        console.warn(err);
        return rejectWithValue({ error: err });
      }
    }
  )
);

const initialState = {
  organisations: <ReadonlyArray<Organisation>>[],
  status: LoadingStatus.idle,
  error: <string>'',
};

const organisationsSlice = createSlice({
  name: 'organisations',
  initialState,
  reducers: {
    clearOrganisations: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getOrganisations.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(getOrganisations.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        organisations: [...action.payload.data],
      };
    });

    builder.addCase(getOrganisations.rejected, (state, action) => {
      return { ...state, status: LoadingStatus.failed, organisations: [] };
    });
  },
});

export const { clearOrganisations } = organisationsSlice.actions;
export default organisationsSlice.reducer;
