import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DailyReviewCompletionRates } from '../../@models/dailyReviewCompletionRates';
import { getDailyReviewCompletionRates } from '../../providers/dailyReviewCompletionRates';
import { LoadingStatus } from '../common';

type DailyReviewCompletionProps = {
  orgId: string;
  timeRange: string;
};

type DailyReviewCompletionRatesResponse = DailyReviewCompletionRates;

type DailyReviewCompletion = AsyncThunk<
  DailyReviewCompletionRatesResponse,
  DailyReviewCompletionProps,
  any
>;

export const getDailyReviewCompletion = <DailyReviewCompletion>(
  createAsyncThunk(
    'reviews/daily-completion',
    async (request, { rejectWithValue }) => {
      try {
        const getDailyReviewCompletionRatesResponse =
          await getDailyReviewCompletionRates(request.orgId, request.timeRange);

        return getDailyReviewCompletionRatesResponse;
      } catch (err) {
        console.warn(err);
        return rejectWithValue({ error: err });
      }
    }
  )
);

const initialState = {
  dailyReviewCompletion: <DailyReviewCompletionRates>{},
  status: LoadingStatus.idle,
  error: <string>'',
};

const dailyReviewCompletionSlice = createSlice({
  name: 'dailyReviewCompletion',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDailyReviewCompletion.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(getDailyReviewCompletion.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        dailyReviewCompletion: { ...action.payload },
      };
    });

    builder.addCase(getDailyReviewCompletion.rejected, (state, action) => {
      // @ts-ignore
      return {
        ...state,
        status: LoadingStatus.failed,
        error: 'Failed to get daily review completion rates',
      };
    });
  },
});

export default dailyReviewCompletionSlice.reducer;
