import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ReviewTeamWithNoMembersResponse } from '../../@models/reviewTeamWithNoMembers';
import { getHealthCheckQuery } from '../../providers/organisations';
import { LoadingStatus } from '../common';

type GetHealthCheckProps = {
  orgId: string;
};

type GetHealthCheckResponse = {
  data: ReviewTeamWithNoMembersResponse;
  meta: any;
};

type GetHealthCheckOverview = AsyncThunk<
  GetHealthCheckResponse,
  GetHealthCheckProps,
  any
>;

export const getHealthCheck = <GetHealthCheckOverview>(
  createAsyncThunk(
    'healthcheckOverview/setHealthcheckOverview',
    async ({ orgId }, { rejectWithValue }) => {
      try {
        return await getHealthCheckQuery(orgId);
      } catch (err) {
        console.warn(err);
        return rejectWithValue({ error: err });
      }
    }
  )
);

const initialState = {
  healthCheckOverview: <ReviewTeamWithNoMembersResponse>{},
  status: LoadingStatus.idle,
  error: <string>'',
};

const healthCheckSlice = createSlice({
  name: 'healthCheckOverview',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getHealthCheck.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });

    builder.addCase(getHealthCheck.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        healthCheckOverview: {
          ...state.healthCheckOverview,
          ...action.payload.data,
        },
      };
    });

    builder.addCase(getHealthCheck.rejected, (state, action) => {
      return { ...state, status: LoadingStatus.failed };
    });
  },
});

export default healthCheckSlice.reducer;
