import Api, { AxiosPromise } from './axioDefault';

export interface CreateOrganisationParams {
  name: string;
  industry: string;
  website: string;
  staffCount: number;
  email: string;
  country: string;
  creatorEmailInOrg: string;
}

export interface GetOrganisationsParams {
  searchString: string;
}

export async function createOrganisationQuery(
  params: CreateOrganisationParams
): AxiosPromise {
  return await Api.post('orgs/v1', params);
}

export async function getOrganisationsQuery(
  params: GetOrganisationsParams
): AxiosPromise {
  return await Api.post('orgs/v1/query', { textSearch: params.searchString });
}

export async function getHealthCheckQuery(orgId: string): AxiosPromise {
  return await Api.post(`/orgs/v1/healthcheck/${orgId}`);
}
