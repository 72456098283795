import Api, { AxiosPromise } from './axioDefault';

async function importAccredsUsersCsvMutation(
  orgId: string,
  csvImportId: string
): AxiosPromise {
  const body = {
    orgId: orgId,
    csvImportId: csvImportId,
  };

  return await Api.post(`accreds/users/v1/import-from-csv`, body);
}

export { importAccredsUsersCsvMutation };
