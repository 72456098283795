import Api, { AxiosPromise } from './axioDefault';

async function getDailyReviewCompletionRates(
  orgId: string,
  timeRange: string
): AxiosPromise {
  const body = {
    orgId: orgId,
    timeRange: timeRange + 'd',
  };

  return await Api.post(`stats/challenges/v1/review-completion`, body);
}

export { getDailyReviewCompletionRates };
