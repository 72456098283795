import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  ChallengerActivityResponseModel,
  ReviewerActivity,
  TaskSpecSummary,
} from '../../@models/activity';
import {
  getChallengerActivityQuery,
  getReviewerActivityQuery,
  getTaskSpecActivityQuery,
} from '../../providers/challengeStats';
import { LoadingStatus } from '../common';

type GetReviewerActivityResponse = {
  data: ReviewerActivity[];
  meta: {};
};

type GetChallengerActivityResponse = {
  data: ChallengerActivityResponseModel;
  meta: {};
};

type GetActivityProps = {
  orgId: string;
  timeRange: string;
  teamId?: string;
};

type GetTaskSpecActivityProps = {
  orgId: string;
  timeRange: string;
};

type GetReviewerActivity = AsyncThunk<
  GetReviewerActivityResponse,
  GetActivityProps,
  any
>;

type GetChallengerActivity = AsyncThunk<
  GetChallengerActivityResponse,
  GetActivityProps,
  any
>;

type GetTaskSpecActivityResponse = {
  data: TaskSpecSummary[];
  meta: {};
};

type GetTaskSpecActivity = AsyncThunk<
  GetTaskSpecActivityResponse,
  GetTaskSpecActivityProps,
  any
>;

export const getReviewerActivity = <GetReviewerActivity>(
  createAsyncThunk(
    'stats-challenges/getReviewerActivity',
    async (request, { rejectWithValue }) => {
      try {
        return await getReviewerActivityQuery(
          request.orgId,
          request.timeRange,
          request.teamId
        );
      } catch (err) {
        console.warn(err);
        return rejectWithValue({ error: err });
      }
    }
  )
);

export const getChallengerActivity = <GetChallengerActivity>(
  createAsyncThunk(
    'stats-challenges/getChallengerActivity',
    async (request, { rejectWithValue }) => {
      try {
        var result = await getChallengerActivityQuery(
          request.orgId,
          request.timeRange,
          request.teamId
        );

        return result;
      } catch (err) {
        console.warn(err);
        return rejectWithValue({ error: err });
      }
    }
  )
);

export const getTaskSpecActivity = <GetTaskSpecActivity>(
  createAsyncThunk(
    'stats-challenges/getTaskSpecActivitySummary',
    async (request, { rejectWithValue }) => {
      try {
        return await getTaskSpecActivityQuery(request.orgId, request.timeRange);
      } catch (err) {
        console.warn(err);
        return rejectWithValue({ error: err });
      }
    }
  )
);

const initialState = {
  reviewerActivities: <ReviewerActivity[]>[],
  challengerActivity: <ChallengerActivityResponseModel>{},
  taskSpecActivity: <TaskSpecSummary[]>[],
  status: LoadingStatus.idle,
  error: <string>'',
};

const challengeStatsSlice = createSlice({
  name: 'challengeStats',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getReviewerActivity.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(getReviewerActivity.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        reviewerActivities: [...action.payload.data],
      };
    });

    builder.addCase(getReviewerActivity.rejected, (state, action) => {
      // @ts-ignore
      return {
        ...state,
        status: LoadingStatus.failed,
        error: 'Failed to get reviewer activity',
      };
    });

    builder.addCase(getTaskSpecActivity.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(getTaskSpecActivity.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        taskSpecActivity: [...action.payload.data],
      };
    });

    builder.addCase(getTaskSpecActivity.rejected, (state, action) => {
      // @ts-ignore
      return {
        ...state,
        status: LoadingStatus.failed,
        error: 'Failed to get task spec activities',
      };
    });

    builder.addCase(getChallengerActivity.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(getChallengerActivity.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        challengerActivity: { ...action.payload.data },
      };
    });

    builder.addCase(getChallengerActivity.rejected, (state, action) => {
      // @ts-ignore
      return {
        ...state,
        status: LoadingStatus.failed,
        error: 'Failed to get challenger activity',
      };
    });
  },
});

export default challengeStatsSlice.reducer;
