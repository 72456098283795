import Api, { AxiosPromise } from './axioDefault';

async function getReviewerActivityQuery(
  orgId: string,
  timeRange: string,
  teamId?: string
): AxiosPromise {
  const body = {
    orgId: orgId,
    timeRange: timeRange,
    teamId: teamId,
  };

  return await Api.post(`stats/challenges/v1/reviewer-activity`, body);
}

async function getChallengerActivityQuery(
  orgId: string,
  timeRange: string,
  teamId?: string
): AxiosPromise {
  const body = {
    orgId: orgId,
    timeRange: timeRange,
    teamId: teamId,
  };

  return await Api.post(`stats/challenges/v1/challenger-activity`, body);
}

async function getChallengeSummaryQuery(
  orgId: string,
  timeRange: string,
  teamId?: string
) {
  const body = {
    orgId: orgId,
    timeRange: timeRange,
    teamId: teamId,
  };

  return await Api.post(`stats/challenges/v1/activity`, body);
}

async function getTaskSpecActivityQuery(orgId: string, timeRange: string) {
  const body = {
    orgId: orgId,
    timeRange: timeRange,
  };

  return await Api.post(`stats/challenges/v1/task-spec-activity`, body);
}

async function getTaskSpecComponentActivityQuery(
  orgId: string,
  timeRange: string,
  taskSpecId: string
) {
  const body = {
    orgId: orgId,
    timeRange: timeRange,
    taskSpecId: taskSpecId,
  };

  return await Api.post(
    `stats/challenges/v1/task-spec-activity-by-component`,
    body
  );
}

async function getReviewDiscrepanciesQuery(
  orgId: string,
  timeRange: string,
  teamId?: string
) {
  const body = {
    orgId: orgId,
    timeRange: timeRange,
    teamId: teamId,
  };

  return await Api.post(`stats/challenges/v1/review-discrepancy`, body);
}

async function getChallengesWithReviewDiscrepanciesQuery(
  orgId: string,
  date: Date,
  teamId?: string
) {
  const body = {
    orgId: orgId,
    date: date,
    teamId: teamId,
  };

  return await Api.post(`stats/challenges/v1/review-discrepancy-date`, body);
}

async function getTaskSpecUserPerformanceQuery(
  orgId: string,
  timeRange: string
) {
  const body = {
    orgId: orgId,
    timeRange: timeRange,
  };

  return await Api.post(`stats/challenges/v1/task-spec-user-performance`, body);
}

export {
  getReviewerActivityQuery,
  getChallengerActivityQuery,
  getReviewDiscrepanciesQuery,
  getChallengesWithReviewDiscrepanciesQuery,
  getChallengeSummaryQuery,
  getTaskSpecActivityQuery,
  getTaskSpecUserPerformanceQuery,
  getTaskSpecComponentActivityQuery,
};
