import Api, { AxiosPromise } from './axioDefault';

type GetOrganisationMembersRequest = {
  role?: string;
  teamId?: string;
  searchString?: string;
  active: boolean;
};

async function getOrganisationMembersQuery(
  orgId: string,
  request: GetOrganisationMembersRequest
): AxiosPromise {
  const body = {
    role: request.role,
    teamId: request.teamId,
    searchString: request.searchString ?? null,
    active: request.active,
  };

  return await Api.post(`/orgs/v1/${orgId}/members/query`, body);
}

async function deleteOrganisationMemberAsync(
  orgId: string,
  userId: string
): AxiosPromise {
  return await Api.delete(`/orgs/v1/${orgId}/members/${userId}`);
}

async function getOrganisationMemberDetailsAsync(
  orgId: string,
  userId: string
): AxiosPromise {
  return await Api.get(`/orgs/v1/${orgId}/members/${userId}`);
}

export {
  getOrganisationMembersQuery,
  deleteOrganisationMemberAsync,
  getOrganisationMemberDetailsAsync,
};
