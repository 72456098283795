import {
  CreateTaskScheduleRequest,
  UpdateTaskScheduleRequest,
} from '../@models/taskSchedule';
import Api, { AxiosPromise } from './axioDefault';

async function getTaskSchedulesQuery(taskId: string): AxiosPromise {
  return await Api.get(`/task-specs/v1/${taskId}/schedules`);
}

async function createTaskScheduleMutation(
  request: CreateTaskScheduleRequest
): AxiosPromise {
  const body = {
    ...request,
  };

  return await Api.post(`/task-specs/v1/${request.taskSpecId}/schedules`, body);
}

async function updateTaskScheduleMutation(
  request: UpdateTaskScheduleRequest
): AxiosPromise {
  const body = {
    ...request,
  };

  return await Api.put(
    `/task-specs/v1/${request.taskSpecId}/schedules/${request.id}`,
    body
  );
}

async function deleteTaskScheduleMutation(
  taskId: string,
  taskScheduleId: string
): AxiosPromise {
  return await Api.delete(
    `/task-specs/v1/${taskId}/schedules/${taskScheduleId}`
  );
}

export {
  getTaskSchedulesQuery,
  createTaskScheduleMutation,
  updateTaskScheduleMutation,
  deleteTaskScheduleMutation,
};
