import { createSlice, createAsyncThunk, AsyncThunk } from '@reduxjs/toolkit';
import { TeamMember } from '../../@models/teamMember';
import { getOrganisationMembersQuery } from '../../providers/organisationMembers';
import {
  addTeamMembersMutation,
  removeTeamMembersMutation,
} from '../../providers/teamMembers';
import { LoadingStatus } from '../common';

type GetTeamMembersProps = {
  orgId: string;
  teamId: string;
  searchString?: string;
};

type GetTeamMembersResponse = {
  data: TeamMember[];
  meta: any;
};

type GetTeamMembers = AsyncThunk<
  GetTeamMembersResponse,
  GetTeamMembersProps,
  any
>;

type AddTeamMembersProps = {
  userIds: string[];
  teamId: string;
};

type AddTeamMembersResponse = {
  data: string[];
  meta: any;
};

type AddTeamMembers = AsyncThunk<
  AddTeamMembersResponse,
  AddTeamMembersProps,
  any
>;

type RemoveTeamMembersProps = {
  userIds: string[];
  teamId: string;
};

type RemoveTeamMembersResponse = {
  data: string[];
  meta: any;
};

type RemoveTeamMembers = AsyncThunk<
  RemoveTeamMembersResponse,
  RemoveTeamMembersProps,
  any
>;

function MapResponseToTeamMember(
  responseObject: any,
  teamMemberIds: string[]
): TeamMember {
  return {
    id: responseObject.userId,
    orgId: responseObject.orgId,
    userId: responseObject.userId,
    username: responseObject.handle,
    name: responseObject.forenames + ' ' + responseObject.lastname,
    isInTeam: teamMemberIds.includes(responseObject.userId),
    inviteEmail: responseObject.inOrgEmail,
    jobTitle: responseObject.jobTitle,
  };
}

export const getTeamMembers = <GetTeamMembers>(
  createAsyncThunk(
    'teams/setTeamMembers',
    async ({ orgId, teamId, searchString }, { rejectWithValue }) => {
      try {
        //first we get all members from the organisation
        const allOrganisationMembersResponse =
          await getOrganisationMembersQuery(orgId, {
            searchString: searchString,
            active: true,
            teamId: teamId,
          });

        const teamMembersResponse = await getOrganisationMembersQuery(orgId, {
          searchString: searchString,
          teamId: teamId,
          active: true,
        });
        //userId is a unique field under this scenario
        const teamMemberIds = teamMembersResponse.data.map(
          (teamMember: any) => teamMember.userId
        );

        var result = {
          data: allOrganisationMembersResponse.data.map(
            (organisationMember: any) =>
              MapResponseToTeamMember(organisationMember, teamMemberIds)
          ),
          meta: {},
        };

        return result;
      } catch (err) {
        console.warn(err);
        return rejectWithValue({ error: err });
      }
    }
  )
);

export const addTeamMembers = <AddTeamMembers>(
  createAsyncThunk(
    'teams/addTeamMembers',
    async ({ teamId, userIds }, { rejectWithValue }) => {
      try {
        await addTeamMembersMutation({
          teamId: teamId,
          userIds: userIds,
        });

        const result = {
          data: userIds,
          meta: {},
        };

        return result;
      } catch (err) {
        console.warn(err);
        return rejectWithValue({ error: err });
      }
    }
  )
);

export const removeTeamMembers = <RemoveTeamMembers>(
  createAsyncThunk(
    'teams/removeTeamMembers',
    async ({ teamId, userIds }, { rejectWithValue }) => {
      try {
        await removeTeamMembersMutation({
          teamId: teamId,
          userIds: userIds,
        });

        const result = {
          data: userIds,
          meta: {},
        };

        return result;
      } catch (err) {
        console.warn(err);
        return rejectWithValue({ error: err });
      }
    }
  )
);

const initialState = {
  teamMembers: <TeamMember[]>[],
  status: LoadingStatus.idle,
  error: <string>'',
};

const teamsSlice = createSlice({
  name: 'teamMembers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTeamMembers.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(getTeamMembers.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        teamMembers: [...action.payload.data],
      };
    });

    builder.addCase(getTeamMembers.rejected, (state, action) => {
      // @ts-ignore
      return { ...state, status: LoadingStatus.failed, teamMembers: [] };
    });
    builder.addCase(addTeamMembers.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(addTeamMembers.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        teamMembers: state.teamMembers.map((teamMember: TeamMember) => {
          return {
            ...teamMember,
            isInTeam: action.payload.data.includes(teamMember.userId),
          };
        }),
      };
    });

    builder.addCase(addTeamMembers.rejected, (state, action) => {
      // do nothing
      return {
        ...state,
        status: LoadingStatus.failed,
      };
    });
    builder.addCase(removeTeamMembers.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(removeTeamMembers.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        teamMembers: state.teamMembers.map((teamMember: TeamMember) => {
          return {
            ...teamMember,
            isInTeam: !action.payload.data?.includes(teamMember.userId),
          };
        }),
      };
    });

    builder.addCase(removeTeamMembers.rejected, (state, action) => {
      // do nothing
      return {
        ...state,
        status: LoadingStatus.failed,
      };
    });
  },
});

export default teamsSlice.reducer;
