import {
  AsyncThunk,
  createAsyncThunk,
  createSlice,
  nanoid,
} from '@reduxjs/toolkit';
import { bulkImportMutation } from '../../providers/uploadInvitesCsvFile';
import { LoadingStatus } from '../common';
import { Storage } from 'aws-amplify';

type UploadInvitesCsvProps = {
  orgId: string;
  file: File;
};
type UploadInvitesCsvResponse = void;
type UploadInvitesCsv = AsyncThunk<
  UploadInvitesCsvResponse,
  UploadInvitesCsvProps,
  {
    rejectValue: {
      error: string;
    };
  }
>;

export const uploadInvitesCsv = <UploadInvitesCsv>(
  createAsyncThunk(
    'invitesCsvFile/upload',
    async ({ orgId, file }, { rejectWithValue }) => {
      const importId = nanoid();

      //we separate the calls into two parts, for handling different error
      try {
        await Storage.put(`${orgId}/uploads/${importId}.csv`, file, {
          contentType: 'text/csv',
          level: 'public',
        });
      } catch (err: any) {
        console.warn(err);
        return rejectWithValue({
          error: err,
        });
      }

      try {
        await bulkImportMutation({
          orgId: orgId,
          importId: importId,
        });
      } catch (err: any) {
        console.warn(err);

        if (
          err?.response?.data?.meta?.error?.errorEmails ||
          err?.response?.data?.meta?.error?.errorTeamIds ||
          err?.response?.data?.meta?.error?.errorRoles
        ) {
          let emailError = `The following emails are invalid\n ${err?.response?.data?.meta?.error?.errorEmails.join(
            '\n'
          )}`;
          let teamIdErrors = `The following team ids are invalid\n ${err?.response?.data?.meta?.error?.errorTeamIds.join(
            '\n'
          )}`;
          let roleErrors = `The following roles are invalid\n ${err?.response?.data?.meta?.error?.errorRoles.join(
            '\n'
          )}`;
          return rejectWithValue({
            error: [emailError, teamIdErrors, roleErrors].join('\n'),
          });
        }

        return rejectWithValue({
          error: err,
        });
      }
    }
  )
);

const initialState = {
  status: LoadingStatus.idle,
  error: <string | null>null,
};

const invitesCsvFileSlice = createSlice({
  name: 'invitesCsvFile',
  initialState,
  reducers: {
    clearUploadInvitesCsv: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(uploadInvitesCsv.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(uploadInvitesCsv.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
      };
    });

    builder.addCase(uploadInvitesCsv.rejected, (state, action) => {
      return {
        status: LoadingStatus.failed,
        error: action.payload?.error ?? null,
      };
    });
  },
});

export const { clearUploadInvitesCsv } = invitesCsvFileSlice.actions;

export default invitesCsvFileSlice.reducer;
