const config = {
  aws_endpoint_override: '',
  aws: {
    Auth: {
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_VCb3jE53n',
      userPoolWebClientId: '56msv9fj3gphc965htdu2t4810',
      identityPoolId: 'eu-west-1:6454b61c-ae70-4686-a72b-6e406a881a4e',
    },
    Storage: {
      bucket: 'visibly-phoenix-storage',
      region: 'eu-west-1',
      identityPoolId: 'eu-west-1:6454b61c-ae70-4686-a72b-6e406a881a4e',
    },
  },
  "baseUrl": "https://api.play.visiblyhq.com",
  "appStoreUrl": "https://apps.apple.com/gb/app/visibly-hq/id1596143776",
  "playStoreUrl": "https://play.google.com/store/apps/details?id=com.visiblyhq.phoenix",
  sentry: {
    "dsn":"https://be68ba66fb4940ad9e326d8962542b80@o1073413.ingest.sentry.io/6440448",
    "environment": "Development",
    "release": "dev-6e22f505ace64b419a14a51d680f0171c9e25cf6",
    "enabled": true
  },
  intercom: {
    "appId": "cv1baon2"
  }
};
export default config;
