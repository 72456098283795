import { TaskScheduleCadence } from './taskSchedule';

export interface TaskSpecContent {
  taskType: string | null;
}

interface TaskContent {
  taskType: string;
  id: string;
}

export interface MediaBasedTaskSpecContent extends TaskSpecContent {
  challengeInstruction: string | null;
  reviewInstruction: string | null;
  maxEvidenceItemCount: number | null;
}

export interface MixedTaskContent extends TaskSpecContent {
  challenges: TaskContent[];
}

export interface MediaBasedTaskContent extends TaskContent {
  challengeInstruction: string | null;
  reviewInstruction: string | null;
  maxEvidenceItemCount: number | null;
  headline: string | null;
}

export interface MultiChoiceQuestionContent extends TaskContent {
  question: string;
  answers: MultiChoiceAnswer[];
}

interface MultiChoiceAnswer {
  text: string;
  isCorrect: boolean | null;
}

export interface TaskRequest {
  name: string;
  taskType: TaskType;
  headline: string;
  cadence: TaskScheduleCadence;
  content: TaskSpecContent;
  defaultRequiredReviewCount: number;
  defaultReviewWindowInHours: number;
  visibility?: TaskVisibility;
  defaultReviewType?: ReviewType;
}

export interface CreateTaskRequest extends TaskRequest {
  orgId: string;
}

export interface UpdateTaskRequest extends CreateTaskRequest {
  id: string;
  baseVersion: number;
}

export interface GetTasksRequest {
  orgId: string;
  searchString: string | null;
}

export enum TaskType {
  photo = 'photo',
  video = 'video',
  multiChoiceQuestion = 'multiChoiceQuestion',
  mixed = 'mixed',
}

export enum TaskVisibility {
  private = 'private',
  public = 'public',
  org = 'org',
  shared = 'shared',
}

export enum ReviewType {
  empty = '',
  single = 'single',
  multi = 'multi',
  hierarchical = 'hierarchical',
}

// Because the BE sends different value for Task and Full task
interface Cadences {
  cadence: TaskScheduleCadence;
  defaultCadence: TaskScheduleCadence;
}

interface RequiredReviewCounts {
  requiredReviewCount: number;
  defaultRequiredReviewCount: number;
}

interface ReviewWindows {
  reviewWindowInHours: number;
  defaultReviewWindowInHours: number;
}

type Cadence = RequireOnlyOne<Cadences, 'cadence' | 'defaultCadence'>;
type RequiredReviewCount = RequireOnlyOne<
  RequiredReviewCounts,
  'requiredReviewCount' | 'defaultRequiredReviewCount'
>;
type ReviewWindow = RequireOnlyOne<
  ReviewWindows,
  'reviewWindowInHours' | 'defaultReviewWindowInHours'
>;

// The full task as received from the BE
export type FullTask = {
  id: string;
  name: string;
  orgId: string;
  taskType: TaskType;
  createdAt: string;
  headline?: string;
  version: number;
  content: TaskSpecContent;
  creatorId?: string;
  releasorId?: string;
  visibility: TaskVisibility;
  defaultReviewType: ReviewType;
} & Cadence &
  RequiredReviewCount &
  ReviewWindow;

export type TaskMain = Pick<
  FullTask,
  | 'id'
  | 'name'
  | 'orgId'
  | 'taskType'
  | 'createdAt'
  | 'cadence'
  | 'requiredReviewCount'
  | 'reviewWindowInHours'
  | 'visibility'
  | 'defaultReviewType'
  | 'content'
>;

export type TaskDetails = Pick<
  FullTask,
  'headline' | 'version' | 'creatorId' | 'releasorId'
>;

export type Task = TaskMain & { extra: TaskDetails };
