import { styled as muiStyled } from '@material-ui/core';
import { getColor, getFontSize } from './Theme';

const Label = muiStyled('h1')(() => ({
  color: getColor('primaryText'),
  fontSize: getFontSize('xLarge'),
  fontWeight: 'normal',
}));

export default Label;
