import { Redirect, Route, RouteProps } from 'react-router-dom';
import { VisiblyGroups } from '../@models/user';
import { useSelector } from '../state/store';

type Props = {
  children: any;
  path: string;
  exact?: boolean;
};

function AdminRoute({ children, ...rest }: Props) {
  const user = useSelector((state) => state.user.user);
  const render = ({ location }: RouteProps) => {
    if (
      user?.groups?.includes(VisiblyGroups['visibly-admin-group']) ||
      user?.groups?.includes(VisiblyGroups['visibly-support-group'])
    ) {
      return children;
    }

    return (
      <Redirect
        to={{
          pathname: '/dashboard',
          state: { from: location },
        }}
      />
    );
  };

  return <Route {...rest} render={render} />;
}

export default AdminRoute;
