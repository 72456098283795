import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getOrganisationOverviewQuery } from '../../providers/organisationOverview';
import { LoadingStatus } from '../common';

import { OrganisationOverview } from '../../@models/organisationOverview';

type GetOrganisationOverviewProps = {
  orgId: string;
};

type GetOrganisationOverviewResponse = {
  data: OrganisationOverview;
  meta: any;
};

type GetOrganisationOverview = AsyncThunk<
  GetOrganisationOverviewResponse,
  GetOrganisationOverviewProps,
  any
>;

export const getOrganisationOverview = <GetOrganisationOverview>(
  createAsyncThunk(
    'organisation/overview',
    async (request, { rejectWithValue }) => {
      try {
        const getOrganisationOverviewResponse =
          await getOrganisationOverviewQuery(request.orgId);

        return getOrganisationOverviewResponse;
      } catch (err) {
        console.warn(err);
        return rejectWithValue({ error: err });
      }
    }
  )
);

const initialState = {
  organisationOverview: <OrganisationOverview>{},
  status: LoadingStatus.idle,
  error: <string>'',
};

const organisationOverviewSlice = createSlice({
  name: 'organisationOverview',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrganisationOverview.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(getOrganisationOverview.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        organisationOverview: { ...action.payload.data },
      };
    });

    builder.addCase(getOrganisationOverview.rejected, (state, action) => {
      // @ts-ignore
      return {
        ...state,
        status: LoadingStatus.failed,
        error: 'Failed to get organisation overview',
      };
    });
  },
});

export default organisationOverviewSlice.reducer;
