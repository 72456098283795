import { AxiosInstance } from 'axios';
import * as Sentry from '@sentry/react';
import { severityLevelFromString } from '@sentry/utils';

const allowedRetryMethods = ['get', 'put', 'delete', 'head', 'options', 'post'];

type Error = {
  response: Response;
  config: Config;
};

type Response = {
  status: number;
};

type AxiosRetryInterceptorConfig = {
  maxAttempts: number;
  waitTime: number;
  errorCodes: number[];
};

type Config = AxiosRetryInterceptorConfig & {
  method: string;
};

const shouldRetry = (error: Error, retryCount: number) => {
  const { method: httpMethod, errorCodes, maxAttempts } = error.config;
  const {
    response: { status: statusCode },
  } = error;

  if (
    allowedRetryMethods.some((x) => x === httpMethod) &&
    errorCodes.some((x) => x === statusCode) &&
    retryCount < maxAttempts
  ) {
    return true;
  }

  return false;
};

const axiosRetryInterceptor = (
  axios: AxiosInstance,
  axiosRetryInterceptorConfig: AxiosRetryInterceptorConfig
) => {
  axios.interceptors.request.use(
    (config) => Object.assign(config, axiosRetryInterceptorConfig),
    (error) => Promise.reject(error)
  );

  var retryCount = 0;
  axios.interceptors.response.use(
    (response) => response,
    (error: any) => {
      Sentry.addBreadcrumb({
        level: severityLevelFromString('Error'),
        message: 'whole error: ' + JSON.stringify(error),
      });
      Sentry.addBreadcrumb({
        level: severityLevelFromString('Error'),
        message: 'error code: ' + error.code,
      });
      Sentry.addBreadcrumb({
        level: severityLevelFromString('Error'),
        message: 'error message: ' + error.message,
      });
      if (error.code === 'NotAuthorizedException') {
        return Promise.reject(error);
      }
      if (shouldRetry(error, retryCount)) {
        retryCount = retryCount + 1;

        if (error.config.waitTime > 0) {
          // eslint-disable-next-line no-unused-vars
          return new Promise((resolve, reject) => {
            setTimeout(
              () => resolve(axios(error.config)),
              error.config.waitTime
            );
          });
        }
        return axios(error.config);
      }
      retryCount = 0;
      return Promise.reject(error);
    }
  );
};

export default axiosRetryInterceptor;
