import Api, { AxiosPromise } from './axioDefault';

async function getOrganisationOverviewQuery(orgId: string): AxiosPromise {
  const body = {
    orgId: orgId,
  };

  return await Api.post(`stats/orgs/v1/overview`, body);
}

export { getOrganisationOverviewQuery };
