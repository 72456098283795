import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LoadingStatus } from '../common';

import { DailyReviewRatings } from '../../@models/dailyReviewRatings';
import { getDailyReviewRatingsQuery } from '../../providers/dailyReviewRatings';

type GetDailyReviewRatingsProps = {
  orgId: string;
  timeRange: string;
  userId?: string;
};

type GetDailyReviewRatingsResponse = DailyReviewRatings;

type GetDailyReviewRatings = AsyncThunk<
  GetDailyReviewRatingsResponse,
  GetDailyReviewRatingsProps,
  any
>;

export const getDailyReviewRatings = <GetDailyReviewRatings>(
  createAsyncThunk(
    'reviews/daily-ratings',
    async (request, { rejectWithValue }) => {
      try {
        const getDailyReviewRatingsResponse = await getDailyReviewRatingsQuery(
          request.orgId,
          request.timeRange,
          request.userId
        );

        return getDailyReviewRatingsResponse;
      } catch (err) {
        console.warn(err);
        return rejectWithValue({ error: err });
      }
    }
  )
);

const initialState = {
  dailyReviewRatings: <DailyReviewRatings>{},
  status: LoadingStatus.idle,
  error: <string>'',
};

const dailyReviewRatingsSlice = createSlice({
  name: 'dailyReviewRatings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDailyReviewRatings.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(getDailyReviewRatings.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        dailyReviewRatings: { ...action.payload },
      };
    });

    builder.addCase(getDailyReviewRatings.rejected, (state, action) => {
      // @ts-ignore
      return {
        ...state,
        status: LoadingStatus.failed,
        error: 'Failed to get challenges overview',
      };
    });
  },
});

export default dailyReviewRatingsSlice.reducer;
