import {
  configureStore,
  combineReducers,
  createSerializableStateInvariantMiddleware,
  unwrapResult as nativeUnwrapResult,
} from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import {
  TypedUseSelectorHook,
  useDispatch as useNativeDispatch,
  useSelector as useNativeSelector,
} from 'react-redux';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk, { ThunkDispatch } from 'redux-thunk';
import logger from 'redux-logger';
import authReducer from './auth/authSlice';
import industriesReducer from './organisation/industriesSlice';
import invitesReducer from './invites/invitesSlice';
import organisationReducer from './organisation/organisationSlice';
import organisationsReducer from './organisation/organisationsSlice';
import organisationMembersReducer from './organisationMembers/organisationMembersSlice';
import teamsReducer from './teams/teamsSlice';
import teamMembersReducer from './teams/teamMembersSlice';
import tasksReducer from './tasks/tasksSlice';
import tasksSchedulesReducer from './tasks/taskSchedulesSlice';
import userReducer from './user/userSlice';
import invitesCsvFileReducer from './invites/invitesCsvFileSlice';
import alertsReducer from './alerts/alertsSlice';
import localSettingsReducer from './localSettings/localSettingsSlice';
import publicInviteReducer from './invites/publicInviteSlice';
import organisationOverviewReducer from './organisationOverview/organisationOverviewSlice';
import challengeDetailsReducer from './challengeDetails/challengeDetailsSlice';
import reviewsReducer from './reviews/reviewsSlice';
import dailyChallengeCompletionReducer from './dailyChallengeCompletion/dailyChallengeCompletionSlice';
import dailyReviewCompletionReducer from './dailyReviewCompletion/dailyReviewCompletionSlice';
import dailyReviewRatingsReducer from './dailyReviewRatings/dailyReviewRatingsSlice';
import healthCheckReducer from './organisation/healthCheckSlice';
import challengesLibraryReducer from './challengesLibrary/challengesLibrarySlice';
import challengesLibraryTemplatesReducer from './challengesLibrary/challengeLibraryTemplateSlice';
import userStatsReducer from './userStats/userStatsSlice';
import userChallengesReducer from './challenges/userChallengesSlice';
import challengeStatsReducer from './challengeStats/challengeStatsSlice';
import reviewDiscrepancyReducer from './challengeStats/reviewDiscrepancySlice';
import challengeWithReviewDiscrepancyReducer from './challengeStats/challengeWithReviewDiscrepancySlice';
import accredsUsersCsvFileReducer from './accredsUsers/accredsUsersCsvFileSlice';
import tasksStatsReducer from './tasksStats/tasksStatsSlice';
import challengesReducer from './challenges/challengesSlice';
import taskSpecUserPerformanceReducer from './challengeStats/taskSpecUserPerformanceSlice';
import taskSpecComponentActivityReducer from './challengeStats/taskSpecComponentActivitySlice';

const reducers = combineReducers({
  auth: authReducer,
  organisation: organisationReducer,
  organisations: organisationsReducer,
  organisationMembers: organisationMembersReducer,
  industries: industriesReducer,
  invites: invitesReducer,
  publicInvite: publicInviteReducer,
  user: userReducer,
  teams: teamsReducer,
  teamMembers: teamMembersReducer,
  tasks: tasksReducer,
  taskSchedules: tasksSchedulesReducer,
  invitesCsvFile: invitesCsvFileReducer,
  alerts: alertsReducer,
  localSettings: localSettingsReducer,
  organisationOverview: organisationOverviewReducer,
  dailyChallengeCompletion: dailyChallengeCompletionReducer,
  dailyReviewCompletion: dailyReviewCompletionReducer,
  dailyReviewRating: dailyReviewRatingsReducer,
  healthCheckOverview: healthCheckReducer,
  challengesLibrary: challengesLibraryReducer,
  challengesLibraryTemplate: challengesLibraryTemplatesReducer,
  challengeDetails: challengeDetailsReducer,
  reviews: reviewsReducer,
  userStats: userStatsReducer,
  userChallenges: userChallengesReducer,
  challengeStats: challengeStatsReducer,
  reviewDiscrepancies: reviewDiscrepancyReducer,
  challengeWithReviewDiscrepancy: challengeWithReviewDiscrepancyReducer,
  accredsUsersCsvFile: accredsUsersCsvFileReducer,
  tasksStats: tasksStatsReducer,
  challenges: challengesReducer,
  taskSpecUserPerformance: taskSpecUserPerformanceReducer,
  taskSpecComponentActivity: taskSpecComponentActivityReducer,
});

const persistConfig = {
  key: 'root',
  whitelist: ['auth', 'user'],
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const serializableMiddleware = createSerializableStateInvariantMiddleware({
  ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
});

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [serializableMiddleware, thunk, logger],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = ThunkDispatch<typeof store, any, AnyAction>;

// FROM DOCUMENTATION: Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useDispatch = () => useNativeDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = useNativeSelector;

export const unwrapResult = nativeUnwrapResult;

export const persistor = persistStore(store);
export default store;
