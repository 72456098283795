import { UnregisteredUser } from '../@models/user';
import Api, { AxiosPromise } from './axioDefault';

type Props = UnregisteredUser & { password: string; inviteId?: string };

interface RegisterUserRequest {
  forenames: string;
  lastName: string;
  recoveryEmail?: string;
  email: string;
  mobileNumber?: string;
  handle?: string;
  inviteId?: string;
  password: string;
}
async function registerUserMutation(params: Props): AxiosPromise {
  let body: RegisterUserRequest;

  body = {
    forenames: params.name,
    lastName: params.surname,
    email: params.email,
    password: params.password,
    inviteId: params.inviteId,
  };

  if (params.phoneNumber) {
    body.mobileNumber = params.phoneNumber;
  }

  return await Api.post('users/v1', body);
}

async function getUserQuery(userId: string): AxiosPromise {
  return await Api.get(`users/v1/${userId}`);
}

export { registerUserMutation, getUserQuery };
