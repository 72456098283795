import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Challenge } from '../../@models/challenge';
import { challengesUserQuery } from '../../providers/challenges';
import { LoadingStatus } from '../common';

type GetUserChallenges = AsyncThunk<
  GetUserChallengesResponse,
  GetUserChallengesProps,
  any
>;

type GetUserChallengesResponse = {
  data: Challenge[];
  meta: any;
};

type GetUserChallengesProps = {
  userId: string;
  orgId: string;
  states: string[];
  submittedAtTimeRange: string;
  taskSpecId?: string;
};

export const getUserChallenges = <GetUserChallenges>(
  createAsyncThunk(
    'challenges/user-query',
    async (props, { rejectWithValue }) => {
      try {
        return await challengesUserQuery(
          props.userId,
          props.orgId,
          props.states,
          props.submittedAtTimeRange,
          props.taskSpecId
        );
      } catch (err) {
        console.warn(err);
        return rejectWithValue({ error: err });
      }
    }
  )
);

const initialState = {
  userChallenges: <Challenge[]>{},
  status: LoadingStatus.idle,
  error: <string>'',
};

const userChallengesSlice = createSlice({
  name: 'userChallenges',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserChallenges.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(getUserChallenges.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        userChallenges: [...action.payload.data],
      };
    });

    builder.addCase(getUserChallenges.rejected, (state, action) => {
      // @ts-ignore
      return {
        ...state,
        status: LoadingStatus.failed,
        error: 'Failed to get user challenges',
      };
    });
  },
});

export default userChallengesSlice.reducer;
