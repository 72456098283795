import { createSlice } from '@reduxjs/toolkit';

const localSettingsSlice = createSlice({
  name: 'localSettings',
  initialState: {
    includeExpiredChallenges:
      localStorage.getItem('includeExpiredChallenges') ?? false,
  },
  reducers: {
    setIncludeExpiredChallenges: (state, action) => {
      localStorage.setItem('includeExpiredChallenges', action.payload);
      state.includeExpiredChallenges = action.payload;
    },
  },
});

export const { setIncludeExpiredChallenges } = localSettingsSlice.actions;
export default localSettingsSlice.reducer;
