import {
  GetTasksRequest,
  CreateTaskRequest,
  UpdateTaskRequest,
} from '../@models/task';
import Api, { AxiosPromise } from './axioDefault';

async function getTasksQuery(request: GetTasksRequest): AxiosPromise {
  const body = {
    ...request,
  };

  return await Api.post(`/task-specs/v1/query`, body);
}

async function getTaskByIdQuery(taskId: string): AxiosPromise {
  return await Api.get(`/task-specs/v1/${taskId}`);
}

async function createTaskMutation(request: CreateTaskRequest): AxiosPromise {
  const body = {
    name: request.name,
    orgId: request.orgId,
    taskType: request.taskType,
    headline: request.headline,
    cadence: request.cadence,
    content: request.content,
    defaultRequiredReviewCount: request.defaultRequiredReviewCount,
    defaultReviewWindowInHours: request.defaultReviewWindowInHours,
    visibility: request.visibility,
    defaultReviewType: request.defaultReviewType,
  };
  return await Api.post(`/task-specs/v1`, body);
}

async function updateTaskMutation(request: UpdateTaskRequest): AxiosPromise {
  const body = {
    baseVersion: request.baseVersion,
    name: request.name,
    taskType: request.taskType,
    headline: request.headline,
    cadence: request.cadence,
    content: request.content,
    defaultRequiredReviewCount: request.defaultRequiredReviewCount,
    defaultReviewWindowInHours: request.defaultReviewWindowInHours,
    visibility: request.visibility,
    defaultReviewType: request.defaultReviewType,
    orgId: request.orgId,
  };

  return await Api.put(`/task-specs/v1/${request.id}`, body);
}

async function deleteTaskMutation(taskId: string): AxiosPromise {
  return await Api.delete(`/task-specs/v1/${taskId}`);
}

export {
  getTasksQuery,
  getTaskByIdQuery,
  createTaskMutation,
  updateTaskMutation,
  deleteTaskMutation,
};
