import { FeatureFlags } from './organisation';

export interface UnregisteredUser {
  username: string;
  email: string;
  phoneNumber: string;
  name: string;
  surname: string;
  verified: boolean;
  createdAt: string;
}

export interface User extends UnregisteredUser {
  id: string;
  //I temp. created this field to store the visibly org id (aka selected org id)
  currentOrgId: string;
  orgs: UserOrganisation[];
  groups?: VisiblyGroups[];
}

export enum VisiblyGroups {
  'visibly-support-group' = 'visibly-support-group',
  'visibly-admin-group' = 'visibly-admin-group',
}

export interface UserOrganisation {
  id: string;
  name: string;
  role: string;
  featureFlags: FeatureFlags;
  options: OrganisationOptions;
}

export enum OrganisationUserRole {
  Member = 'member',
  Admin = 'admin',
  Owner = 'owner',
}

interface OrganisationOptions {
  reviewHierarchies: ReviewHierarchyOptions[];
}

interface ReviewHierarchyOptions {
  tier: number;
  percentage: number;
}
