import { User } from '../../../@models/user';

export const logEvent = (
  pathname: string,
  eventType: string | undefined,
  user: User,
  properties?: any
) => {
  var path = cleanPathname(pathname, properties);
  window.analytics.track(`${path.pathname}_${eventType}`, {
    orgId: user.currentOrgId,
    userRoleInOrg: user.orgs?.find(
      (element) => element.id === user.currentOrgId
    )?.role,
    ...properties,
  });
};

export const cleanPathname = (pathname: string, properties?: any) => {
  const slashCount = pathname.replace(/.\/$/, '').match(/\//g)?.length;
  if (slashCount !== undefined && slashCount > 1) {
    var pathSections = pathname.split('/');
    if (pathSections[2].length > 0) {
      if (properties) {
        properties.id = pathSections[2];
      } else {
        properties = {
          id: pathSections[2],
        };
      }
    }
    return { pathname: `/${pathSections[1]}`, properties: properties };
  }
  return { pathname: pathname, properties: properties };
};
