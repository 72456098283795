import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ChallengeQuery } from '../../@models/challenge';
import { OutstandingChallengeType } from '../../@models/outstandingChallengeType';
import {
  challengesQuery,
  outstandingReviewsQuery,
  outstandingSubmissionsQuery,
} from '../../providers/challenges';
import { LoadingStatus } from '../common';

type GetChallenges = AsyncThunk<
  GetChallengesResponse,
  GetChallengesRequestModel,
  any
>;

type GetOutstandingChallenges = AsyncThunk<
  GetChallengesResponse,
  GetOutstandingChallengesRequestModel,
  any
>;

type GetChallengesResponse = {
  data: ChallengeQuery[];
  meta: any;
};

export type GetChallengesRequestModel = {
  orgId: string;
  states?: string[];
  challengeCreatedAtTimeRange?: string;
  reviewCreatedAtTimeRange?: string;
  taskSpecId?: string;
  taskSpecMixedContentId?: string;
  challengerUserId?: string;
  reviewerUserId?: string;
};

export type GetOutstandingChallengesRequestModel = {
  orgId: string;
  userId?: string;
  filter?: string;
  type: OutstandingChallengeType;
};

export const getChallenges = <GetChallenges>(
  createAsyncThunk('challenges/query', async (props, { rejectWithValue }) => {
    try {
      return await challengesQuery(props);
    } catch (err) {
      console.warn(err);
      return rejectWithValue({ error: err });
    }
  })
);

export const getOutstandingChallenges = <GetOutstandingChallenges>(
  createAsyncThunk(
    'challenges/outstandingQuery',
    async (props, { rejectWithValue }) => {
      try {
        switch (props.type) {
          case OutstandingChallengeType.reviews:
            return await outstandingReviewsQuery(props);

          case OutstandingChallengeType.submissions:
            return await outstandingSubmissionsQuery(props);
        }
      } catch (err) {
        console.warn(err);
        return rejectWithValue({ error: err });
      }
    }
  )
);

const initialState = {
  challenges: <ChallengeQuery[]>{},
  status: LoadingStatus.idle,
  error: <string>'',
};

const challengesSlice = createSlice({
  name: 'challenges',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getChallenges.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(getChallenges.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        challenges: [...action.payload.data],
      };
    });

    builder.addCase(getChallenges.rejected, (state, action) => {
      // @ts-ignore
      return {
        ...state,
        status: LoadingStatus.failed,
        error: 'Failed to get challenges',
      };
    });
    builder.addCase(getOutstandingChallenges.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(getOutstandingChallenges.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        challenges: [...action.payload.data],
      };
    });

    builder.addCase(getOutstandingChallenges.rejected, (state, action) => {
      // @ts-ignore
      return {
        ...state,
        status: LoadingStatus.failed,
        error: 'Failed to get challenges',
      };
    });
  },
});

export default challengesSlice.reducer;
