import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  alert: null,
};

const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    setAlert: (state, action) => ({ alert: action.payload }),
    clearAlert: () => ({ alert: null }),
  },
});

export const { setAlert, clearAlert } = alertsSlice.actions;
export default alertsSlice.reducer;
