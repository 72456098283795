import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled as muiStyled } from '@material-ui/core';
import Container from '../@Base/Container';
import Label from '../@Base/Label';
import { getColor } from '../@Base/Theme';

const LoadingIconContainer = muiStyled(Container)(() => ({
  flex: 1,
  justifyContent: 'center',
  marginTop: 56,
  marginLeft: 'auto',
  marginRight: 'auto',
}));

const LoadingLabel = muiStyled(Label)(() => ({
  marginTop: 36,
  textAlign: 'center',
}));

const LoadingIcon = muiStyled(FontAwesomeIcon)(() => ({
  marginLeft: 'auto',
  marginRight: 'auto',
  color: '#1f87fe',

  '--fa-secondary-color': '#e4dfff',
}));

type LoadingOverlayProps = {
  title?: string;
};

function LoadingOverlay({ title }: LoadingOverlayProps) {
  return (
    <LoadingIconContainer>
      <LoadingIcon icon={faSpinnerThird} spin={true} size="3x" />
      {title && <LoadingLabel> {title} </LoadingLabel>}
    </LoadingIconContainer>
  );
}

export const LoadingPanel = muiStyled('div')(() => ({
  width: '100%',
  backgroundColor: getColor('neutral'),
  height: 400,
  textAlign: 'center',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
}));

export default LoadingOverlay;
