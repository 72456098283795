import Api, { AxiosPromise } from './axioDefault';

async function getUserSummary(
  userId: string,
  orgId: string,
  timeRange: string
): AxiosPromise {
  const body = {
    userId: userId,
    orgId: orgId,
    timeRange: timeRange,
  };

  return await Api.post(`stats/users/v1/summary`, body);
}

export { getUserSummary };
