import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  CreateOrganisationParams,
  createOrganisationQuery,
} from '../../providers/organisations';
import { OrganisationDetail, Organisation } from '../../@models/organisation';
import { LoadingStatus } from '../common';

type OrganisationResponse = {
  data: Organisation;
  meta: any;
};

type CreateOrganisation = AsyncThunk<
  OrganisationResponse,
  CreateOrganisationParams,
  any
>;

export const createOrganisation = <CreateOrganisation>(
  createAsyncThunk(
    'organisation/setOrganisation',
    async (createParams, { rejectWithValue }) => {
      try {
        const response = await createOrganisationQuery(createParams);

        const result = {
          data: response.data,
          meta: {},
        };

        return result;
      } catch (err) {
        console.warn(err);
        return rejectWithValue({
          error: err,
        });
      }
    }
  )
);

const initialState = {
  status: LoadingStatus.idle,
  error: <string>'',
  organisation: <OrganisationDetail>{},
};

const organisationSlice = createSlice({
  name: 'organisation',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createOrganisation.pending, (state) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(createOrganisation.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        organisation: { ...state.organisation, ...action.payload.data },
      };
    });
    builder.addCase(createOrganisation.rejected, (state) => {
      return {
        ...state,
        status: LoadingStatus.failed,
        error: 'Failed to create organisation',
      };
    });
  },
});

export default organisationSlice.reducer;
