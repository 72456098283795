import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  ChallengeLibraryItem,
  ChallengesLibraryOverview,
} from '../../@models/challengesLibraryOverview';
import { getChallengesLibraryQuery } from '../../providers/challengesLibrary';
import { LoadingStatus } from '../common';

type GetChallengesLibraryResponse = ChallengesLibraryOverview;

type GetChallengesLibraryProps = {
  orgId: string;
  taxonomy?: string;
};

type GetChallengesLibrary = AsyncThunk<
  GetChallengesLibraryResponse,
  GetChallengesLibraryProps,
  any
>;

export const getChallengesLibrary = <GetChallengesLibrary>(
  createAsyncThunk(
    'challenges-library/getChallengesLibraryList',
    async (request, { rejectWithValue }) => {
      try {
        const getChallengesLibraryQueryResponse =
          await getChallengesLibraryQuery(request.orgId, request.taxonomy);

        return getChallengesLibraryQueryResponse;
      } catch (err) {
        console.warn(err);
        return rejectWithValue({ error: err });
      }
    }
  )
);

const initialState = {
  challengesLibrary: <ChallengeLibraryItem[]>[],
  status: LoadingStatus.idle,
  error: <string>'',
};

const challengesLibrarySlice = createSlice({
  name: 'challengesLibrary',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getChallengesLibrary.pending, (state) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });

    builder.addCase(getChallengesLibrary.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        challengesLibrary: [...action.payload.data],
      };
    });

    builder.addCase(getChallengesLibrary.rejected, (state) => {
      // @ts-ignore
      return {
        ...state,
        status: LoadingStatus.failed,
        error: 'Failed to get challenges library list',
      };
    });
  },
});

export default challengesLibrarySlice.reducer;
