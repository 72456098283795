import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ReviewDiscrepancy } from '../../@models/reviewDiscrepancies';
import { getReviewDiscrepanciesQuery } from '../../providers/challengeStats';
import { LoadingStatus } from '../common';

type GetActivityProps = {
  orgId: string;
  timeRange: string;
  teamId?: string;
};

type GetReviewDiscrepanciesResponse = {
  data: ReviewDiscrepancy[];
  meta: {};
};

type GetReviewDiscrepancies = AsyncThunk<
  GetReviewDiscrepanciesResponse,
  GetActivityProps,
  any
>;

export const getReviewDiscrepancies = <GetReviewDiscrepancies>(
  createAsyncThunk(
    'stats-challenges/getReviewDiscrepancies',
    async (request, { rejectWithValue }) => {
      try {
        return await getReviewDiscrepanciesQuery(
          request.orgId,
          request.timeRange,
          request.teamId
        );
      } catch (err) {
        console.warn(err);
        return rejectWithValue({ error: err });
      }
    }
  )
);

const initialState = {
  reviewDiscrepancies: <ReviewDiscrepancy[]>[],
  status: LoadingStatus.idle,
  error: <string>'',
};

const reviewDiscrepancySlice = createSlice({
  name: 'reviewDiscrepancy',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getReviewDiscrepancies.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(getReviewDiscrepancies.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        reviewDiscrepancies: [...action.payload.data],
      };
    });

    builder.addCase(getReviewDiscrepancies.rejected, (state, action) => {
      // @ts-ignore
      return {
        ...state,
        status: LoadingStatus.failed,
        error: 'Failed to get review discrepancies',
      };
    });
  },
});

export default reviewDiscrepancySlice.reducer;
