import {
  createSlice,
  createAsyncThunk,
  AsyncThunk,
  nanoid,
} from '@reduxjs/toolkit';
import {
  CreateTaskRequest,
  UpdateTaskRequest,
  FullTask,
  Task,
  TaskDetails,
  ReviewType,
} from '../../@models/task';
import {
  createTaskMutation,
  getTaskByIdQuery,
  getTasksQuery,
  updateTaskMutation,
} from '../../providers/task';
import { LoadingStatus } from '../common';
import { Storage } from 'aws-amplify';
import { importMixedTasksCsvMutation } from '../../providers/uploadMixedTasksCsvFile';

type GetTaskProps = { id: string };
type GetTasksProps = { orgId: string; searchString: string };
type UploadMixedTasksCsvProps = {
  name: string;
  orgId: string;
  headline: string;
  cadence: string;
  taxonomy?: string;
  visibility?: string;
  file: File;
  reviewWindowInHours: number;
  requiredReviewCount: number;
  reviewType: string;
};

type GetTasksResponse = {
  data: Task[];
  meta: any;
};

type TaskResponse = {
  data: Task;
  meta: any;
};

type GetTasks = AsyncThunk<GetTasksResponse, GetTasksProps, any>;

type GetFullTask = AsyncThunk<TaskResponse, GetTaskProps, any>;
type CreateTask = AsyncThunk<TaskResponse, CreateTaskRequest, any>;
type UpdateTask = AsyncThunk<TaskResponse, UpdateTaskRequest, any>;
type UploadMixedTasksCsv = AsyncThunk<
  TaskResponse,
  UploadMixedTasksCsvProps,
  any
>;

function MapResponseToTask(responseObject: FullTask): Task {
  return {
    id: responseObject.id,
    name: responseObject.name,
    orgId: responseObject.orgId,
    taskType: responseObject.taskType,
    cadence: responseObject.cadence,
    createdAt: responseObject.createdAt,
    requiredReviewCount: responseObject.requiredReviewCount,
    reviewWindowInHours: responseObject.reviewWindowInHours,
    visibility: responseObject.visibility,
    defaultReviewType: responseObject.defaultReviewType,
    content: responseObject.content,
    extra: <TaskDetails>{},
  };
}

function MapResponseToTaskDetail(responseObject: FullTask): Task {
  return {
    id: responseObject.id,
    name: responseObject.name,
    orgId: responseObject.orgId,
    taskType: responseObject.taskType,
    cadence: responseObject.defaultCadence,
    createdAt: responseObject.createdAt,
    requiredReviewCount: responseObject.defaultRequiredReviewCount,
    reviewWindowInHours: responseObject.defaultReviewWindowInHours,
    visibility: responseObject.visibility,
    defaultReviewType: responseObject.defaultReviewType,
    content: responseObject.content,
    extra: {
      headline: responseObject.headline,
      version: responseObject.version,
      creatorId: responseObject.creatorId,
      releasorId: responseObject.releasorId,
    },
  };
}

export const getTasks = <GetTasks>(
  createAsyncThunk(
    'tasks/setTasks',
    async ({ orgId, searchString }, { rejectWithValue }) => {
      try {
        const response = await getTasksQuery({
          orgId: orgId,
          searchString: searchString,
        });

        response.data = response.data.map(MapResponseToTask);

        return response;
      } catch (err) {
        console.warn(err);
        return rejectWithValue({ error: err });
      }
    }
  )
);

export const getFullTask = <GetFullTask>(
  createAsyncThunk('tasks/getFullTask', async ({ id }, { rejectWithValue }) => {
    try {
      const response = await getTaskByIdQuery(id);
      const result = {
        data: MapResponseToTaskDetail(response.data),
        meta: {},
      };

      return result;
    } catch (err: any) {
      console.warn(err);
      return rejectWithValue({
        error: err,
      });
    }
  })
);

export const createTask = <CreateTask>(
  createAsyncThunk(
    'tasks/addTask',
    async (request: CreateTaskRequest, { rejectWithValue }) => {
      try {
        const response = await createTaskMutation(request);
        const result = {
          data: MapResponseToTaskDetail(response.data),
          meta: {},
        };

        return result;
      } catch (err: any) {
        console.warn(err);
        return rejectWithValue({
          error: err,
        });
      }
    }
  )
);

export const updateTask = <UpdateTask>(
  createAsyncThunk(
    'tasks/updateTask',
    async (request: UpdateTaskRequest, { rejectWithValue }) => {
      try {
        const response = await updateTaskMutation(request);
        const result = {
          data: MapResponseToTaskDetail(response.data),
          meta: {},
        };
        return result;
      } catch (err: any) {
        console.warn(err);
        return rejectWithValue({
          error: err,
        });
      }
    }
  )
);

export const uploadMixedTasksCsv = <UploadMixedTasksCsv>(
  createAsyncThunk(
    'mixedTasksCsvFile/upload',
    async (props, { rejectWithValue }) => {
      const importId = nanoid();

      try {
        await Storage.put(
          `${props.orgId}/uploads/${importId}.csv`,
          props.file,
          {
            contentType: 'text/csv',
            level: 'public',
          }
        );
      } catch (err: any) {
        console.warn(err);
        return rejectWithValue({
          error: err,
        });
      }

      try {
        var response = await importMixedTasksCsvMutation({
          name: props.name,
          orgId: props.orgId,
          headline: props.headline,
          cadence: props.cadence,
          taxonomy: props.taxonomy,
          visibility: props.visibility,
          contentCsvImportId: importId,
          reviewType: props.reviewType,
          requiredReviewCount: props.requiredReviewCount,
          reviewWindowInHours: props.reviewWindowInHours,
        });
        return {
          data: MapResponseToTaskDetail(response.data),
          meta: {},
        };
      } catch (err: any) {
        console.warn(err);
        return rejectWithValue({
          error: err,
        });
      }
    }
  )
);

const initialState = {
  tasks: <Task[]>[],
  status: LoadingStatus.idle,
  error: <string>'',
};

const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    clearUploadMixedTasksCsv: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getTasks.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(getTasks.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        tasks: [...action.payload.data],
      };
    });

    builder.addCase(getTasks.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
        tasks: [],
      };
    });
    builder.addCase(createTask.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(createTask.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        tasks: [...state.tasks, action.payload.data],
      };
    });
    builder.addCase(createTask.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
      };
    });
    builder.addCase(getFullTask.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(getFullTask.fulfilled, (state, action) => {
      const { id } = action.payload.data;
      const index = state.tasks.findIndex((task) => task.id === id);
      state.status = LoadingStatus.succeeded;
      // If task already exists, update it
      if (index > -1) {
        state.tasks[index] = action.payload.data;
        return state;
      }
      // Otherwise insert new task
      state.tasks = [...state.tasks, action.payload.data];
      return state;
    });
    builder.addCase(getFullTask.rejected, (state, action) => {
      return { ...state, status: LoadingStatus.failed };
    });
    builder.addCase(updateTask.fulfilled, (state, action) => {
      const tasks = state.tasks.map((task: Task) => {
        if (task.id !== action.payload.data.id) {
          return task;
        }

        //the update task call do not return created at so we want to keep the original one
        return { ...task, ...action.payload.data, createdAt: task.createdAt };
      });

      return {
        ...state,
        status: LoadingStatus.succeeded,
        tasks: [...tasks],
      };
    });
    builder.addCase(updateTask.rejected, (state, action) => {
      return { ...state, status: LoadingStatus.failed };
    });
    builder.addCase(uploadMixedTasksCsv.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(uploadMixedTasksCsv.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        tasks: [...state.tasks, action.payload.data],
      };
    });

    builder.addCase(uploadMixedTasksCsv.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
      };
    });
  },
});

export const { clearUploadMixedTasksCsv } = tasksSlice.actions;

export default tasksSlice.reducer;

export var mapReviewMethodToInt = function (reviewMethod: ReviewType) {
  switch (reviewMethod) {
    case ReviewType.multi:
      return 3;
    case ReviewType.single:
    case ReviewType.hierarchical:
      return 1;
    default:
      return 3;
  }
};
