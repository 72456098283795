import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';
import { LoadingStatus } from '../common';

type LoginProps = { email: string; password: string };
type LoginResult = { token: string; userId: any; email: string };
type LoginError = {
  rejectValue: {
    errorMessage: string;
  };
};

type LoginUser = AsyncThunk<LoginResult, LoginProps, LoginError>;
export const login = <LoginUser>(
  createAsyncThunk(
    'user/login',
    async ({ email, password }, { rejectWithValue }) => {
      try {
        const signInResponse = await Auth.signIn(email, password);
        // TODO: Figure out if we care about any properties here, maybe we do
        // const cognitoUser = await Auth.currentAuthenticatedUser();
        return {
          token: signInResponse.signInUserSession.idToken.jwtToken,
          userId: signInResponse.getUsername(),
          email: signInResponse.attributes['email'],
        };
      } catch (err: any) {
        console.warn(err);

        // TODO: Customize the most major error messages
        return rejectWithValue({
          errorMessage: 'Your email or password are incorrect',
        });
      }
    }
  )
);
const initialState = {
  token: '',
  userId: '',
  email: '',
  status: LoadingStatus.idle,
  error: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action) => ({
      ...state,
      token: action.payload,
    }),
    removeToken: (state) => initialState,
    setCognitoUser: (state, action) => ({
      ...state,
      user: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
        error: '',
      };
    });
    builder.addCase(login.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        ...action.payload,
      };
    });
    builder.addCase(login.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
        error: action.payload?.errorMessage ?? 'Login failed',
      };
    });
  },
});

export const { setToken, removeToken, setCognitoUser } = authSlice.actions;
export default authSlice.reducer;
