import { createSlice, createAsyncThunk, AsyncThunk } from '@reduxjs/toolkit';
import {
  CreateHierarchyTaskScheduleRequest,
  CreateTaskScheduleRequest,
  TaskSchedule,
  UpdateHierarchyTaskScheduleRequest,
  UpdateTaskScheduleRequest,
} from '../../@models/taskSchedule';
import {
  createHierarchyTaskScheduleMutation,
  updateHierarchyTaskScheduleMutation,
} from '../../providers/hierarchyTaskSchedule';
import {
  createTaskScheduleMutation,
  getTaskSchedulesQuery,
  updateTaskScheduleMutation,
} from '../../providers/taskSchedule';
import { LoadingStatus } from '../common';

type GetTaskSchedulesProps = {
  taskId: string;
};
type GetTaskSchedulesResponse = {
  data: TaskSchedule[];
  meta: any;
};

type GetTaskSchedules = AsyncThunk<
  GetTaskSchedulesResponse,
  GetTaskSchedulesProps,
  any
>;

type CreateTaskScheduleProps = CreateTaskScheduleRequest;
type CreateTaskScheduleResponse = {
  data: TaskSchedule;
  meta: any;
};

type CreateTaskSchedule = AsyncThunk<
  CreateTaskScheduleResponse,
  CreateTaskScheduleProps,
  any
>;

type UpdateTaskScheduleProps = UpdateTaskScheduleRequest;
type UpdateTaskScheduleResponse = {
  data: TaskSchedule;
  meta: any;
};

type UpdateTaskSchedule = AsyncThunk<
  UpdateTaskScheduleResponse,
  UpdateTaskScheduleProps,
  any
>;

type CreateHierarchyTaskScheduleProps = CreateHierarchyTaskScheduleRequest;
type UpdateHierarchyTaskScheduleProps = UpdateHierarchyTaskScheduleRequest;

type CreateHierarchyTaskScheduleResponse = {
  data: TaskSchedule;
  meta: any;
};

type UpdateHierarchyTaskScheduleResponse = {
  data: any;
  meta: any;
};

type CreateHierarchyTaskSchedule = AsyncThunk<
  CreateHierarchyTaskScheduleResponse,
  CreateHierarchyTaskScheduleProps,
  any
>;

type UpdateHierarchyTaskSchedule = AsyncThunk<
  UpdateHierarchyTaskScheduleResponse,
  UpdateHierarchyTaskScheduleProps,
  any
>;

function MapResponseToHierarchyTaskSchedule(responseObject: any): TaskSchedule {
  return {
    id: responseObject.id,
    name: responseObject.name,
    taskSpecId: responseObject.taskSpecId,
    challengerTeamId: responseObject.challengerTeamId,
    reviewerTeamId: responseObject.reviewerTeamId,
    cadenceSpec: responseObject.cadenceSpec,
    creatorId: responseObject.creatorId,
    createdAt: responseObject.createdAt,
    active: responseObject.active,
    requiredReviewCount: responseObject.requiredReviewCount,
    reviewWindowInHours: responseObject.reviewWindowInHours,
    reviewType: responseObject.reviewType,
    reviewTiers: responseObject.reviewTiers,
    options: {
      canUseDeviceMediaForChallenges:
        responseObject.options.canUseDeviceMediaForChallenges,
      numberOfChallengesToOffer:
        responseObject.options.numberOfChallengesToOffer,
    },
  };
}

function MapResponseToTaskSchedule(responseObject: any): TaskSchedule {
  return {
    id: responseObject.id,
    name: responseObject.name,
    taskSpecId: responseObject.taskSpecId,
    challengerTeamId: responseObject.challengerTeamId,
    reviewerTeamId: responseObject.reviewerTeamId,
    cadenceSpec: responseObject.cadenceSpec,
    creatorId: responseObject.creatorId,
    createdAt: responseObject.createdAt,
    active: responseObject.active,
    requiredReviewCount: responseObject.requiredReviewCount,
    reviewWindowInHours: responseObject.reviewWindowInHours,
    reviewType: responseObject.reviewType,
    reviewTiers: responseObject.reviewTiers,
    options: {
      canUseDeviceMediaForChallenges:
        responseObject.options.canUseDeviceMediaForChallenges,
      numberOfChallengesToOffer:
        responseObject.options.numberOfChallengesToOffer,
    },
  };
}

export const getTaskSchedules = <GetTaskSchedules>(
  createAsyncThunk(
    'TaskSchedules/setTaskSchedules',
    async ({ taskId }, { rejectWithValue }) => {
      try {
        const response = await getTaskSchedulesQuery(taskId);
        response.data = response.data.map(MapResponseToTaskSchedule);

        return response;
      } catch (err) {
        console.warn(err);
        return rejectWithValue({ error: err });
      }
    }
  )
);

export const createTaskSchedule = <CreateTaskSchedule>(
  createAsyncThunk(
    'taskSchedules/addTaskSchedule',
    async (request: CreateTaskScheduleProps, { rejectWithValue }) => {
      try {
        const response = await createTaskScheduleMutation(request);
        const result = {
          data: MapResponseToTaskSchedule(response.data),
          meta: response.data.meta,
        };

        return result;
      } catch (err: any) {
        console.warn(err);
        return rejectWithValue({
          error: err,
        });
      }
    }
  )
);

export const updateTaskSchedule = <UpdateTaskSchedule>(
  createAsyncThunk(
    'taskSchedules/updateTaskSchedule',
    async (request: UpdateTaskScheduleProps, { rejectWithValue }) => {
      try {
        await updateTaskScheduleMutation(request);

        var result = {
          data: request,
          meta: {},
        };

        return result; //backend is not returning anything, so we just pass out input back when success
      } catch (err: any) {
        console.warn(err);
        return rejectWithValue({
          error: err,
        });
      }
    }
  )
);

export const createHierarchyTaskSchedule = <CreateHierarchyTaskSchedule>(
  createAsyncThunk(
    'taskSchedules/addHierarchyTaskSchedule',
    async (request: CreateHierarchyTaskScheduleProps, { rejectWithValue }) => {
      try {
        const response = await createHierarchyTaskScheduleMutation(request);

        const result = {
          data: MapResponseToHierarchyTaskSchedule(response.data),
          meta: {},
        };

        return result;
      } catch (err: any) {
        console.warn(err);
        return rejectWithValue({
          error: err,
        });
      }
    }
  )
);

export const updateHierarchyTaskSchedule = <UpdateHierarchyTaskSchedule>(
  createAsyncThunk(
    'taskSchedules/updateHierarchyTaskSchedule',
    async (request: UpdateHierarchyTaskScheduleProps, { rejectWithValue }) => {
      try {
        await updateHierarchyTaskScheduleMutation(request);

        var result = {
          data: request,
          meta: {},
        };

        return result;
      } catch (err: any) {
        console.warn(err);
        return rejectWithValue({
          error: err,
        });
      }
    }
  )
);

const initialState = {
  taskSchedules: <TaskSchedule[]>[],
  status: LoadingStatus.idle,
  error: <string>'',
};

const TaskSchedulesSlice = createSlice({
  name: 'taskSchedules',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTaskSchedules.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(getTaskSchedules.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        taskSchedules: [...action.payload.data],
      };
    });

    builder.addCase(getTaskSchedules.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
        taskSchedules: [],
      };
    });
    builder.addCase(createTaskSchedule.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(createTaskSchedule.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        taskSchedules: [...state.taskSchedules, action.payload.data],
      };
    });
    builder.addCase(createTaskSchedule.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
      };
    });
    builder.addCase(updateTaskSchedule.fulfilled, (state, action) => {
      const taskSchedules = state.taskSchedules.map(
        (taskSchedule: TaskSchedule) => {
          if (taskSchedule.id !== action.payload.data.id) {
            return taskSchedule;
          }
          return action.payload.data;
        }
      );

      return {
        ...state,
        status: LoadingStatus.succeeded,
        taskSchedules: [...taskSchedules],
      };
    });
    builder.addCase(updateTaskSchedule.rejected, (state, action) => {
      return { ...state, status: LoadingStatus.failed };
    });
    builder.addCase(createHierarchyTaskSchedule.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(createHierarchyTaskSchedule.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        taskSchedules: [...state.taskSchedules, action.payload.data],
      };
    });
    builder.addCase(createHierarchyTaskSchedule.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
      };
    });
    builder.addCase(updateHierarchyTaskSchedule.fulfilled, (state, action) => {
      const taskSchedules = state.taskSchedules.map(
        (taskSchedule: TaskSchedule) => {
          if (taskSchedule.id !== action.payload.data.id) {
            return taskSchedule;
          }
          return action.payload.data;
        }
      );

      return {
        ...state,
        status: LoadingStatus.succeeded,
        taskSchedules: [...taskSchedules],
      };
    });
    builder.addCase(updateHierarchyTaskSchedule.rejected, (state, action) => {
      return { ...state, status: LoadingStatus.failed };
    });
  },
});

export default TaskSchedulesSlice.reducer;
