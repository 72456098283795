import React, { lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';
import { useSelector } from '../state/store';
import AdminRoute from './adminRoute';
import AuthRoute from './authRoute';
import PrivateRoute from './privateRoute';
import { cleanPathname } from '../components/@Base/Events/Common';
import { useIntercom } from 'react-use-intercom';
import LoadingOverlay from '../components/@Common/LoadingOverlay';

const CsvImportOrganisationListPage = lazy(
  () => import('../pages/Admin/CsvImportOrganisationListPage')
);
const UploadInvitesCsvPage = lazy(
  () => import('../pages/Admin/UploadInvitesCsvPage')
);
const UploadMixedTasksCsvPage = lazy(
  () => import('../pages/Admin/UploadMixedTasksCsvPage')
);
const UploadAccredsUsersCsvPage = lazy(
  () => import('../pages/Admin/UploadAccredsUsersCsvPage')
);
const RegisterConfirmPage = lazy(
  () => import('../pages/Register/RegisterConfirmPage')
);
const Login = lazy(() => import('../pages/Auth/LoginPage'));
const PasswordRecoverExpiredPage = lazy(
  () => import('../pages/Auth/PasswordRecoverExpiredPage')
);
const PasswordRecoverPage = lazy(
  () => import('../pages/Auth/PasswordRecoverPage')
);
const PasswordRecoverSuccessPage = lazy(
  () => import('../pages/Auth/PasswordRecoverSuccessPage')
);
const PasswordResetPage = lazy(() => import('../pages/Auth/PasswordResetPage'));
const ChooseOrganisationPage = lazy(
  () => import('../pages/ChooseOrganisation/ChooseOrganisationPage')
);
const Challenges = lazy(() => import('../pages/Member/Challenges'));
const ChallengeCreatePage = lazy(
  () => import('../pages/Member/Challenges/ChallengeCreatePage')
);
const ChallengeEditPage = lazy(
  () => import('../pages/Member/Challenges/ChallengeEditPage')
);
const ChallengeDetailPage = lazy(
  () => import('../pages/Member/Challenges/ChallengeDetailPage')
);
const Dashboard = lazy(() => import('../pages/Member/Dashboard/DashboardPage'));
const TeamCreatePage = lazy(
  () => import('../pages/Member/ManageTeams/TeamCreatePage')
);
const TeamEditPage = lazy(
  () => import('../pages/Member/ManageTeams/TeamEditPage')
);
const DownloadAppPage = lazy(() => import('../pages/Register/DownloadAppPage'));
const OrganisationCreatePage = lazy(
  () => import('../pages/Register/OrganisationCreatePage')
);
const RegisterPage = lazy(() => import('../pages/Register/RegisterPage'));
const RegisterSuccessPage = lazy(
  () => import('../pages/Register/RegisterSuccessPage')
);
const WelcomePage = lazy(() => import('../pages/WelcomePage'));
const InviteSuccessPage = lazy(
  () => import('../pages/Register/InviteSuccessPage')
);
const RegisterConfirmExpiredPage = lazy(
  () => import('../pages/Register/RegisterConfirmExpiredPage')
);
const GetTheAppPage = lazy(() => import('../pages/Register/GetTheAppPage'));
const ChallengesLibrary = lazy(
  () => import('../pages/Member/ChallengesLibrary')
);
const UserProfilePage = lazy(
  () => import('../pages/Member/ManageUsers/UserProfilePage')
);
const AnalyticsPage = lazy(() => import('../pages/Analytics/AnalyticsPage'));
const ManageUsersPage = React.lazy(
  () => import('../pages/Member/ManageUsers/ManageUsersPage')
);
const InviteCreatePage = React.lazy(
  () => import('../pages/Member/ManageUsers/InviteCreatePage')
);
const ManageTeamsPage = React.lazy(
  () => import('../pages/Member/ManageTeams/ManageTeamsPage')
);
const ChallengeListPage = lazy(
  () => import('../pages/Analytics/ChallengeListPage')
);
const ChallengesOutstandingListPage = lazy(
  () => import('../pages/Analytics/ChallengesOutstandingListPage')
);
const ChallengeSetComponentSummaryPage = lazy(
  () => import('../pages/Analytics/ChallengeSetComponentSummaryPage')
);

declare global {
  interface Window {
    analytics: any;
    intercomSettings: any;
    Intercom: any;
  }
}

function usePageView() {
  const location = useLocation();
  const currentUser = useSelector((state) => state.user.user);

  React.useEffect(() => {
    var path = cleanPathname(location.pathname);
    window.analytics.page(path.pathname, {
      orgId: currentUser.currentOrgId,
      userRoleInOrg: currentUser.orgs?.find(
        (element) => element.id === currentUser.currentOrgId
      )?.role,
      ...path.properties,
    });
  });
}

function Routes(): React.ReactElement {
  return (
    <Router>
      <Switches />
    </Router>
  );
}

function Switches(): React.ReactElement {
  const token = useSelector((state) => state.auth.token);
  const { boot } = useIntercom();
  const auth = !!token;

  boot();

  usePageView();

  return (
    <Suspense fallback={<LoadingOverlay />}>
      <Switch>
        <Route path="/welcome">
          <WelcomePage />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/recover-password/">
          <PasswordRecoverPage />
        </Route>

        <Route path="/recover-password-success">
          <PasswordRecoverSuccessPage />
        </Route>

        <Route path="/recover-password-expired">
          <PasswordRecoverExpiredPage />
        </Route>

        <Route path="/reset-password">
          <PasswordResetPage />
        </Route>

        <Route path="/register">
          <RegisterPage />
        </Route>

        <Route path="/register-confirm">
          <RegisterConfirmPage />
        </Route>

        <Route path="/register-confirm-expired">
          <RegisterConfirmExpiredPage />
        </Route>

        <Route path="/register-success">
          <RegisterSuccessPage />
        </Route>

        <Route path="/download-app">
          <DownloadAppPage />
        </Route>

        <Route path="/invite-success">
          <InviteSuccessPage />
        </Route>

        <PrivateRoute path="/create-organisation" {...{ auth }}>
          <OrganisationCreatePage />
        </PrivateRoute>

        <Route path="/create-organisation">
          <OrganisationCreatePage />
        </Route>

        <PrivateRoute path="/choose-organisation" {...{ auth }}>
          <ChooseOrganisationPage />
        </PrivateRoute>

        <AuthRoute path="/dashboard" {...{ auth }}>
          <Dashboard />
        </AuthRoute>
        <AuthRoute path="/challenges" {...{ auth }}>
          <Challenges />
        </AuthRoute>
        <AuthRoute path="/challenges-library" {...{ auth }}>
          <ChallengesLibrary />
        </AuthRoute>
        <AuthRoute path="/new-challenge" {...{ auth }}>
          <ChallengeCreatePage />
        </AuthRoute>
        <AuthRoute path="/edit-challenge/:id" {...{ auth }}>
          <ChallengeEditPage />
        </AuthRoute>
        <AuthRoute path="/challenge-detail/:id" {...{ auth }}>
          <ChallengeDetailPage />
        </AuthRoute>

        <AuthRoute path="/manage-users" {...{ auth }}>
          <ManageUsersPage />
        </AuthRoute>
        <AuthRoute path="/user-profile/:id" {...{ auth }}>
          <UserProfilePage />
        </AuthRoute>

        <AuthRoute path="/new-invite" {...{ auth }}>
          <InviteCreatePage />
        </AuthRoute>

        <AuthRoute path="/manage-teams" {...{ auth }}>
          <ManageTeamsPage />
        </AuthRoute>

        <AuthRoute path="/create-team" {...{ auth }}>
          <TeamCreatePage />
        </AuthRoute>

        <AuthRoute path="/edit-team/:id" {...{ auth }}>
          <TeamEditPage />
        </AuthRoute>

        <AuthRoute path="/analytics" {...{ auth }}>
          <AnalyticsPage />
        </AuthRoute>

        <AuthRoute path="/challenge-list" {...{ auth }}>
          <ChallengeListPage />
        </AuthRoute>

        <AuthRoute path="/challenges-outstanding-list" {...{ auth }}>
          <ChallengesOutstandingListPage />
        </AuthRoute>

        <AuthRoute path="/challenge-set-summary/:taskSpecId" {...{ auth }}>
          <ChallengeSetComponentSummaryPage />
        </AuthRoute>

        <AuthRoute path="/get-the-app" {...{ auth }}>
          <GetTheAppPage />
        </AuthRoute>

        <AdminRoute path="/csv-imports">
          <CsvImportOrganisationListPage />
        </AdminRoute>

        <AdminRoute path="/upload-invites/:orgId">
          <UploadInvitesCsvPage />
        </AdminRoute>

        <AdminRoute path="/upload-tasks/:orgId">
          <UploadMixedTasksCsvPage />
        </AdminRoute>

        <AdminRoute path="/upload-accreds-users/:orgId">
          <UploadAccredsUsersCsvPage />
        </AdminRoute>

        <Route exact path="/*">
          <Redirect to={auth ? '/dashboard' : '/welcome'} />
        </Route>
      </Switch>
    </Suspense>
  );
}

export default Routes;

export interface SignupFlags {
  inviteId: string | null;
  password: string;
  email?: string;
  downloadAppPage: boolean;
  createOrganisationPage: boolean;
}
