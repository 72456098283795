import axios, { AxiosResponse } from 'axios';
import { Auth } from 'aws-amplify';
import config from '../config';
import store from '../state/store';
import { removeToken, setToken } from '../state/auth/authSlice';
import ApiResponseModel from './baseResponse';
import { v4 as uuidv4 } from 'uuid';
import axiosRetryInterceptor from './axiosRetryInterceptor';
import * as Sentry from '@sentry/react';

class VisiblyError extends Error {
  status: any;
  errorMessage: string;
  errorCode: string;

  constructor(status: any, errorMessage: string, errorCode: string) {
    super();
    this.status = status;
    this.errorMessage = errorMessage;
    this.errorCode = errorCode;
  }
}

const instance = axios.create({
  baseURL: config.baseUrl,
});

const correlationIdHeader = 'visibly-correlation-id';

axiosRetryInterceptor(instance, {
  maxAttempts: 3,
  waitTime: 3000,
  errorCodes: [500, 501, 403],
});

instance.interceptors.response.use((res) => {
  var responseParsed = <ApiResponseModel>res.data;
  return responseParsed;
});

instance.interceptors.request.use(
  async (config) => {
    if (config.headers) {
      // Only bother if we've logged on
      if (store.getState().auth.token) {
        let currentToken = store.getState().auth.token;

        try {
          await Auth.currentSession().then((data) => {
            const latestToken = data.getIdToken().getJwtToken();

            if (latestToken) {
              // TODO: Validate if this is necessary
              if (currentToken !== latestToken) {
                store.dispatch(setToken(latestToken));
              }

              // @ts-ignore
              config.headers.Authorization = `Bearer ${latestToken}`;
            }
          });
        } catch (err: any) {
          if (
            err === 'No current user' ||
            err.code === 'NotAuthorizedException'
          ) {
            await Auth.signOut();
            store.dispatch(removeToken());
            localStorage.clear();
          }

          throw err;
        }

        // If we've already loaded user detail from visibly, then we can add org ID too
        if (store.getState().user.user) {
          if (store.getState().user.user.currentOrgId) {
            config.headers['visibly-org-id'] =
              store.getState().user.user.currentOrgId;
          }
        }
      }

      const correlationId = uuidv4();
      config.headers[correlationIdHeader] = correlationId;

      console.debug(
        `correlationId: $correlationId added to request with url: ${config.url} and method: ${config.method}`
      );
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const handleError = (err: any) => {
  Sentry.captureException(err);
  if (err?.response && err.response.status) {
    if (!(err.response?.data?.meta || err?.response.data?.meta?.errorMessage)) {
      //if the error does not conform with the standard pattern, rethrow it
      throw err;
    }

    throw new VisiblyError(
      err.response.status,
      err.response.data.meta.errorMessage || '',
      err.response.data.meta.errorCode || ''
    );
  }
  throw new Error(err);
};

const API = {
  get: (url: string) => {
    const { stack } = new Error();

    return instance
      .get(url)
      .catch((error: { stack: any }) => {
        var { stack: newStack } = new Error();
        error.stack = combineStackTrace(stack, newStack);
        throw error;
      })
      .catch(handleError);
  },
  post: (url: string, body?: any) => {
    const { stack } = new Error();

    return instance
      .post(url, body || {})
      .catch((error: { stack: any }) => {
        var { stack: newStack } = new Error();
        error.stack = combineStackTrace(stack, newStack);
        throw error;
      })
      .catch(handleError);
  },
  put: (url: string, body?: any) => {
    const { stack } = new Error();

    return instance
      .put(url, body || {})
      .catch((error: { stack: any }) => {
        var { stack: newStack } = new Error();
        error.stack = combineStackTrace(stack, newStack);
        throw error;
      })
      .catch(handleError);
  },
  delete: (url: string) => {
    const { stack } = new Error();

    return instance
      .delete(url)
      .catch((error: { stack: any }) => {
        var { stack: newStack } = new Error();
        error.stack = combineStackTrace(stack, newStack);
        throw error;
      })
      .catch(handleError);
  },
};

const combineStackTrace = (
  originalStack: string | undefined,
  newStack: string | undefined
) => {
  let newSplit = (newStack ? newStack : '').split('\n');

  let originalSplit = (originalStack ? originalStack : '').split('\n');

  var split = [...newSplit, ...originalSplit.splice(1)];

  return split.join('\n');
};

// TODO: Fix the type properly
export type AxiosPromise = Promise<AxiosResponse<any, any>>;

export default API;
