import {
  GetInvitesRequest,
  CreateInviteRequest,
  GetAndAssociateInviteRequest,
  ResendInviteRequest,
} from '../@models/invite';
import Api, { AxiosPromise } from './axioDefault';

async function getAndAssociateInvitesMutation(
  request: GetAndAssociateInviteRequest
): AxiosPromise {
  const body = {
    inviteId: request.inviteId ?? null,
    userId: request.userId,
  };

  return await Api.post(`invites/v1/get-and-associate`, body);
}

async function acceptInviteMutation(
  request: GetAndAssociateInviteRequest
): AxiosPromise {
  const body = {
    inviteId: request.inviteId ?? null,
    userId: request.userId,
  };

  return await Api.post(`invites/v1/accept`, body);
}

async function getPublicInviteByIdQuery(id: string): AxiosPromise {
  return await Api.get(`invites/v1/public/${id}`);
}

async function getInvitesQuery(request: GetInvitesRequest): AxiosPromise {
  const body = {
    orgId: request.orgId,
    forenames: request.name,
    lastName: request.surname,
    searchString: request.searchString ?? null,
    handle: request.username,
    email: request.email,
  };

  return await Api.post(`invites/v1/query`, body);
}

async function createInviteMutation(
  request: CreateInviteRequest
): AxiosPromise {
  const body = {
    forenames: request.name,
    lastName: request.surname,
    inviteEmail: request.email,
    orgId: request.orgId,
    inviteData: {
      titleInOrg: request.titleInOrg,
      role: request.role,
      teamIds: request.teamIds,
      optionalMessage: request.optionalMessage,
    },
  };

  return await Api.post(`invites/v1`, body);
}

async function resendInviteMutation(
  request: ResendInviteRequest
): AxiosPromise {
  const body = {
    orgId: request.orgId,
    inviteId: request.inviteId,
  };

  return await Api.post(`invites/v1/resend-invite`, body);
}

export {
  getInvitesQuery,
  createInviteMutation,
  getAndAssociateInvitesMutation,
  acceptInviteMutation,
  getPublicInviteByIdQuery,
  resendInviteMutation,
};
