import Api, { AxiosPromise } from './axioDefault';

export type TaskSpecChallengeActivitiesRequest = {
  orgId: string;
  userId?: string;
  timeRange: string;
  taskSpecId?: string | null;
};

export async function taskSpecChallengeActivitiesQuery(
  request: TaskSpecChallengeActivitiesRequest
): AxiosPromise {
  const body = {
    orgId: request.orgId,
    userId: request.userId,
    timeRange: request.timeRange,
    taskSpecId: request.taskSpecId,
  };
  return await Api.post(
    `stats/tasks/v1/challenger-activity-by-task-spec`,
    body
  );
}
