import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TaskSpecSummary } from '../../@models/activity';
import { getTaskSpecComponentActivityQuery } from '../../providers/challengeStats';
import { LoadingStatus } from '../common';

type GetTaskSpecComponentActivityProps = {
  orgId: string;
  timeRange: string;
  taskSpecId: string;
};

type GetTaskSpecComponentActivityResponse = {
  data: TaskSpecSummary[];
  meta: {};
};

type GetTaskSpecComponentActivity = AsyncThunk<
  GetTaskSpecComponentActivityResponse,
  GetTaskSpecComponentActivityProps,
  any
>;

export const getTaskSpecComponentActivity = <GetTaskSpecComponentActivity>(
  createAsyncThunk(
    'stats-challenges/getTaskSpecComponentActivitySummary',
    async (request, { rejectWithValue }) => {
      try {
        return await getTaskSpecComponentActivityQuery(
          request.orgId,
          request.timeRange,
          request.taskSpecId
        );
      } catch (err) {
        console.warn(err);
        return rejectWithValue({ error: err });
      }
    }
  )
);

const initialState = {
  taskSpecComponentActivities: <TaskSpecSummary[]>[],
  status: LoadingStatus.idle,
  error: <string>'',
};

const taskSpecComponentActivitySlice = createSlice({
  name: 'taskSpecComponentActivity',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTaskSpecComponentActivity.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(getTaskSpecComponentActivity.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        taskSpecComponentActivities: [...action.payload.data],
      };
    });

    builder.addCase(getTaskSpecComponentActivity.rejected, (state, action) => {
      // @ts-ignore
      return {
        ...state,
        status: LoadingStatus.failed,
        error: 'Failed to get task spec activities',
      };
    });
  },
});

export default taskSpecComponentActivitySlice.reducer;
