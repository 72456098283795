import {
  GetChallengesRequestModel,
  GetOutstandingChallengesRequestModel,
} from '../state/challenges/challengesSlice';
import Api, { AxiosPromise } from './axioDefault';

async function getChallengeDetailsQuery(challengeId: string): AxiosPromise {
  return await Api.get(`challenges/v1/${challengeId}`);
}

async function queryReviews(
  challengeId: string,
  states: string[],
  reviewerTeamId?: string | null,
  maxReviews?: number | null
): AxiosPromise {
  const body = {
    challengeId: challengeId,
    reviewerTeamId: reviewerTeamId,
    states: states,
    maxReviews: maxReviews,
  };

  return await Api.post(`challenges/v1/reviews-query`, body);
}

async function challengesUserQuery(
  userId: string,
  orgId: string,
  states: string[],
  submittedAtTimeRange: string,
  taskSpecId?: string | null
): AxiosPromise {
  const body = {
    userId: userId,
    orgId: orgId,
    states: states,
    submittedAtTimeRange: submittedAtTimeRange,
    taskSpecId: taskSpecId,
  };

  return await Api.post(`challenges/v1/user-query`, body);
}

async function challengesQuery(
  request: GetChallengesRequestModel
): AxiosPromise {
  return await Api.post(`challenges/v1/query`, request);
}

async function outstandingSubmissionsQuery(
  request: GetOutstandingChallengesRequestModel
): AxiosPromise {
  return await Api.post(`challenges/v1/query-outstanding-submissions`, request);
}

async function outstandingReviewsQuery(
  request: GetOutstandingChallengesRequestModel
): AxiosPromise {
  return await Api.post(`challenges/v1/query-outstanding-reviews`, request);
}

export {
  getChallengeDetailsQuery,
  queryReviews,
  challengesUserQuery,
  challengesQuery,
  outstandingSubmissionsQuery,
  outstandingReviewsQuery,
};
