import { createSlice, createAsyncThunk, AsyncThunk } from '@reduxjs/toolkit';
import { UserSummary as UserStatsSummary } from '../../@models/userSummary';
import { getUserSummary } from '../../providers/userSummary';
import { LoadingStatus } from '../common';

type GetSummary = AsyncThunk<SummaryResponse, SummaryProps, any>;

type SummaryResponse = {
  data: UserStatsSummary;
  meta: any;
};

type SummaryProps = {
  orgId: string;
  userId: string;
  timeRange: string;
};

export const getUserStatsSummary = <GetSummary>(
  createAsyncThunk('userStats/summary', async (props, { rejectWithValue }) => {
    try {
      const response = await getUserSummary(
        props.userId,
        props.orgId,
        props.timeRange
      );

      return response;
    } catch (err) {
      console.warn(err);
      return rejectWithValue({ error: err });
    }
  })
);

const initialState = {
  userStatsSummary: <UserStatsSummary>{},
  status: LoadingStatus.idle,
  error: <string>'',
};

const userStatsSlice = createSlice({
  name: 'userSummary',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserStatsSummary.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(getUserStatsSummary.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        userStatsSummary: action.payload.data,
      };
    });
    builder.addCase(getUserStatsSummary.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
        userStatsSummary: <UserStatsSummary>{},
      };
    });
  },
});

export default userStatsSlice.reducer;
