import { createSlice, createAsyncThunk, AsyncThunk } from '@reduxjs/toolkit';
import { OrganisationMember } from '../../@models/organisationMember';
import {
  deleteOrganisationMemberAsync,
  getOrganisationMembersQuery,
  getOrganisationMemberDetailsAsync,
} from '../../providers/organisationMembers';
import { LoadingStatus } from '../common';

type GetOrganisationMembersProps = {
  orgId: string;
  searchString: string;
  active: boolean;
};

type GetOrganisationMembersResponse = {
  data: OrganisationMember[];
  meta: any;
};

type DeleteOrganisationMemberProps = {
  orgId: string;
  userId: string;
};

type DeleteOrganisationMemberResponse = {
  data: any;
  meta: any;
};

type GetOrganisationMemberDetailsProps = {
  orgId: string;
  userId: string;
};

type GetOrganisationMemberDetailsResponse = {
  data: OrganisationMember;
  meta: any;
};

type GetOrganisationMembers = AsyncThunk<
  GetOrganisationMembersResponse,
  GetOrganisationMembersProps,
  any
>;

type DeleteOrganisationMember = AsyncThunk<
  DeleteOrganisationMemberResponse,
  DeleteOrganisationMemberProps,
  any
>;

type GetOrganisationMemberDetails = AsyncThunk<
  GetOrganisationMemberDetailsResponse,
  GetOrganisationMemberDetailsProps,
  any
>;

function MapResponseToOrganisationMember(
  responseObject: any
): OrganisationMember {
  return {
    id: responseObject.userId,
    orgId: responseObject.orgId,
    userId: responseObject.userId,
    handle: responseObject.handle,
    name: responseObject.forenames,
    surname: responseObject.lastname,
    role: responseObject.role,
    email: responseObject.inOrgEmail,
    active: responseObject.active,
    createdAt: responseObject.createdAt,
    inviter: responseObject.inviter,
    jobTitle: responseObject.jobTitle,
    teams: responseObject.teams,
  };
}

export const getOrganisationMembers = <GetOrganisationMembers>(
  createAsyncThunk(
    'organisationMembers/setOrganisationMembers',
    async ({ orgId, searchString, active }, { rejectWithValue }) => {
      try {
        const response = await getOrganisationMembersQuery(orgId, {
          searchString: searchString,
          active: active,
        });

        const result = {
          data: response.data.map(MapResponseToOrganisationMember),
          meta: {},
        };

        return result;
      } catch (err) {
        console.warn(err);
        return rejectWithValue({ error: err });
      }
    }
  )
);

export const deleteOrganisationMember = <DeleteOrganisationMember>(
  createAsyncThunk(
    'organisationMembers/deleteOrganisationMember',
    async ({ orgId, userId }, { rejectWithValue }) => {
      try {
        await deleteOrganisationMemberAsync(orgId, userId);

        const result = {
          data: [],
          meta: {},
        };

        return result;
      } catch (err: any) {
        console.warn(err);
        return rejectWithValue({ error: err });
      }
    }
  )
);

export const getOrganisationMemberDetails = <GetOrganisationMemberDetails>(
  createAsyncThunk(
    'organisationMembers/getOrganisationMemberDetails',
    async ({ orgId, userId }, { rejectWithValue }) => {
      try {
        const response = await getOrganisationMemberDetailsAsync(orgId, userId);

        const result = {
          data: {
            orgId: response.data.orgId,
            userId: response.data.userId,
            handle: response.data.handle,
            name: response.data.forenames,
            surname: response.data.lastName,
            role: response.data.role,
            email: response.data.inOrgEmail,
            active: response.data.active,
            createdAt: response.data.createdAt,
            inviter: response.data.inviter,
            jobTitle: response.data.jobTitle,
            teams: response.data.teams,
          },
          meta: {},
        };
        return result;
      } catch (err) {
        console.warn(err);
        return rejectWithValue({ error: err });
      }
    }
  )
);

const initialState = {
  organisationMembers: <OrganisationMember[]>[],
  organisationMemberDetails: <OrganisationMember>{},
  status: LoadingStatus.idle,
  error: <string>'',
};

const organisationMembersSlice = createSlice({
  name: 'organisationMembers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrganisationMembers.pending, (state, action) => {
      return {
        ...state,
        error: '',
        organisationMembers: [],
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(getOrganisationMembers.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        organisationMembers: [...action.payload.data],
      };
    });

    builder.addCase(getOrganisationMembers.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
        organisationMembers: [],
      };
    });

    builder.addCase(deleteOrganisationMember.pending, (state, action) => {
      return {
        ...state,
        error: '',
        organisationMembers: [],
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(deleteOrganisationMember.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        organisationMembers: [...action.payload.data],
      };
    });

    builder.addCase(deleteOrganisationMember.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
        organisationMembers: [],
      };
    });

    builder.addCase(getOrganisationMemberDetails.pending, (state, action) => {
      return {
        ...state,
        error: '',
        organisationMemberDetails: <OrganisationMember>{},
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(getOrganisationMemberDetails.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        organisationMemberDetails: { ...action.payload.data },
      };
    });

    builder.addCase(getOrganisationMemberDetails.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
        organisationMemberDetails: <OrganisationMember>{},
      };
    });
  },
});

export default organisationMembersSlice.reducer;
