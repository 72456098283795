import { createSlice, createAsyncThunk, AsyncThunk } from '@reduxjs/toolkit';
import { CreateTeamRequest, Team, UpdateTeamRequest } from '../../@models/team';
import {
  getTeamsQuery,
  getTeamByIdQuery,
  createTeamMutation,
  updateTeamMutation,
} from '../../providers/teams';
import { LoadingStatus } from '../common';

type GetTeamsProps = {
  orgId: string;
  name?: string | null;
};
type GetTeamsResponse = {
  data: Team[];
  meta: any;
};

type GetTeams = AsyncThunk<GetTeamsResponse, GetTeamsProps, any>;

type GetTeamProps = {
  id: string;
};
type GetTeamResponse = {
  data: Team;
  meta: any;
};

type GetTeam = AsyncThunk<GetTeamResponse, GetTeamProps, any>;

type CreateTeamProps = CreateTeamRequest;
type CreateTeamResponse = Team;
type CreateTeam = AsyncThunk<CreateTeamResponse, CreateTeamProps, any>;

type UpdateTeamProps = UpdateTeamRequest;
type UpdateTeamResponse = {
  data: Team;
  meta: any;
};

type UpdateTeam = AsyncThunk<UpdateTeamResponse, UpdateTeamProps, any>;

export const getTeams = <GetTeams>(
  createAsyncThunk(
    'teams/setTeams',
    async ({ orgId, name }, { rejectWithValue }) => {
      try {
        const response = await getTeamsQuery({
          orgId: orgId,
          name: name,
        });

        return response;
      } catch (err) {
        console.warn(err);
        return rejectWithValue({ error: err });
      }
    }
  )
);

export const createTeam = <CreateTeam>(
  createAsyncThunk('teams/addTeam', async (request, { rejectWithValue }) => {
    try {
      const response = await createTeamMutation(request);

      return response.data;
    } catch (err: any) {
      console.warn(err);
      const errorMessage =
        err?.errorCode === '409-TEAMS-TeamNameConflict'
          ? `Team name already in use, please use another`
          : null;

      return rejectWithValue({
        error: errorMessage,
      });
    }
  })
);

export const getTeam = <GetTeam>(
  createAsyncThunk('teams/getTeam', async (request, { rejectWithValue }) => {
    try {
      const response = await getTeamByIdQuery(request.id);

      return response;
    } catch (err) {
      console.warn(err);
      return rejectWithValue({ error: err });
    }
  })
);

export const updateTeam = <UpdateTeam>(
  createAsyncThunk('teams/updateTeam', async (request, { rejectWithValue }) => {
    try {
      var response = await updateTeamMutation(request);
      response.data = request;

      return response;
    } catch (err) {
      console.warn(err);
      return rejectWithValue({ error: err });
    }
  })
);

const initialState = {
  teams: <Team[]>[],
  status: LoadingStatus.idle,
  error: <string>'',
};

const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTeams.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(getTeams.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        teams: [...action.payload.data],
      };
    });
    builder.addCase(getTeams.rejected, (state, action) => {
      return { ...state, status: LoadingStatus.failed, teams: [] };
    });
    builder.addCase(createTeam.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(createTeam.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        teams: [...state.teams, action.payload],
      };
    });
    builder.addCase(createTeam.rejected, (state, action) => {
      return { ...state, status: LoadingStatus.failed };
    });
    builder.addCase(getTeam.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(getTeam.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        teams: [...state.teams, action.payload.data],
      };
    });
    builder.addCase(getTeam.rejected, (state, action) => {
      return { ...state, status: LoadingStatus.failed };
    });
    builder.addCase(updateTeam.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(updateTeam.fulfilled, (state, action) => {
      const teams = state.teams.map((team: Team) => {
        if (team.id !== action.payload.data.id) {
          return team;
        }
        return { ...team, ...action.payload.data };
      });

      return {
        ...state,
        status: LoadingStatus.succeeded,
        teams: [...teams, action.payload.data],
      };
    });
    builder.addCase(updateTeam.rejected, (state, action) => {
      return { ...state, status: LoadingStatus.failed };
    });
  },
});

export default teamsSlice.reducer;
