import { createTheme } from '@material-ui/core';

type JustifyContent =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'space-evenly';

type AlignItems = 'start' | 'flex-end' | 'center' | 'flex-start' | 'stretch';

type FlexDirection = 'row' | 'column' | 'row-reverse';

export type FontWeight = 'normal' | 'bold' | 'bolder' | 'lighter';

export enum Colors {
  // Primary: Mainly for the background. Used also in plain index.css
  primary = '#f4f6fA',
  secondary = '#7265e3',
  primaryText = '#2d3142',
  secondaryText = '#af8eff',
  tabActiveText = '#7A6EE5',
  buttonPrimary = '#7265e3',
  buttonSecondary = '#E4DFFF',
  fadedText = '#9c9eb9',
  buttonDisabled = '#9c9eb9',
  black = '#000',
  neutral = '#fff',
  warning = 'yellow',
  error = '#e42032',
  gridOddRow = '#f4f6fA',
  gridEvenRow = '#FFFFFF',
  gridBase = '#FFFFFF',
  disabledInput = '#F5F5F5',
  disabledInputBorder = '#9d9dc9',
  excellentScore = '#00c81f',
  doingWellScore = '#bcfc44',
  letsImproveScore = '#fcd434',
  doesNotDemonstrateScore = '#fb2c3c',
  insufficientDataScore = '#F5F5F5',
  excellentDarkerScore = '#00AE1B',
  doingWellDarkerScore = '#AAE33E',
  letsImproveDarkerScore = '#E8C330',
  doesNotDemonstrateDarkerScore = '#E72736',
  insufficientDataDarkerScore = '#EBEBEB',
  backgroundPieChart = '#E7E7E7',
}

export enum FontSizes {
  xxLarge = '24px',
  xLarge = '18px',
  large = '16px',
  normal = '14px',
  small = '12px',
}

export enum Radius {
  primary = '20px',
  secondary = '0',
  inputPrimary = '10px',
  inputSecondary = '16px',
}

type FontSizesKey = keyof typeof FontSizes;
export type ColorsKey = keyof typeof Colors;
type RadiusKey = keyof typeof Radius;

export const getFontSize = (key?: FontSizesKey) =>
  key ? FontSizes[key] : undefined;
export const getColor = (key?: ColorsKey) => (key ? Colors[key] : undefined);
export const getRadius = (key?: RadiusKey) => (key ? Radius[key] : undefined);
export const getFontWeight = (weight: FontWeight) => {
  if (weight === 'bold') {
    return { fontFamily: 'Rubik-Medium', fontWeight: 'normal' };
  }
  if (weight === 'normal') {
    return { fontFamily: 'Rubik-Regular', fontWeight: 'normal' };
  }
  return { fontFamily: 'Rubik-Regular', fontWeight: 'normal' };
};
export interface StyleProps {
  /** Fonts */
  $fontSize?: FontSizesKey;
  $fontWeight?: FontWeight;
  /** Colors */
  $color?: ColorsKey;
  $backgroundColor?: ColorsKey;
  $borderColor?: ColorsKey;
  $errorColor?: ColorsKey;
  /**  Positioning */
  $justifyContent?: JustifyContent;
  $alignItems?: AlignItems;
  $flexDirection?: FlexDirection;
  $alignSelf?: AlignItems;
  $textAlign?: string;
  /** Design */
  $height?: string;
  $width?: string;
  $borderRadius?: RadiusKey;
  $marginTop?: number;
  $margin?: string | number;
  $padding?: string | number;
  /** Mode */
  $link?: boolean;
}

const defaultTheme = createTheme();

export const visiblyTheme = createTheme({
  palette: {
    background: {
      default: '#f4f6fA',
    },
    primary: {
      main: '#f4f6fA',
    },
    secondary: {
      main: '#7265e3',
    },
  },
  typography: {
    fontFamily: getFontWeight('normal').fontFamily,
    button: {
      textTransform: 'none',
    },
  },
  overrides: {
    MuiLink: {
      root: {
        color: '#59B9FD',
      },
    },
    MuiToolbar: {
      gutters: {
        [defaultTheme.breakpoints.up('sm')]: {
          paddingLeft: 16,
          paddingRight: 16,
        },
        paddingLeft: defaultTheme.spacing(0),
        paddingRight: defaultTheme.spacing(0),
      },
    },
    MuiDrawer: {
      root: {
        width: 326,
        display: 'flex',
        flexDirection: 'column',
      },
      paper: {
        width: 274,
        paddingLeft: 42,
        paddingTop: 32,
      },
    },
    MuiTabs: {
      root: {
        color: getColor('secondaryText'),
        '& .MuiTabs-indicator': {
          backgroundColor: getColor('secondaryText'),
        },
      },
    },
    MuiTab: {
      root: {
        '@media(min-width:600px)': {
          minWidth: 100,
        },
      },
    },
    MuiButton: {
      sizeLarge: {
        fontSize: getFontSize('large'),
        fontWeight: 600,
        lineHeight: '19px',
        padding: '17px',
      },
      outlinedPrimary: {
        border: `1px solid ${getColor('primary')}`,
        color: getColor('primary'),
      },
      contained: {
        paddingTop: 5,
        paddingBottom: 5,
        border: `1px solid ${getColor('secondary')}`,
        backgroundColor: getColor('secondary'),
        color: getColor('neutral'),
        '&:disabled': {
          border: `1px solid ${getColor('disabledInputBorder')}`,
          backgroundColor: getColor('disabledInput'),
        },
        boxShadow:
          '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        '&:hover': {
          backgroundColor: getColor('secondary'),
          boxShadow:
            '0px 3px 1px -2px rgb(0 0 0 / 50%), 0px 2px 2px 0px rgb(0 0 0 / 44%), 0px 1px 5px 0px rgb(0 0 0 / 42%)',
        },
      },
      outlined: {
        paddingTop: 5,
        paddingBottom: 5,
        borderColor: getColor('secondary'),
        color: getColor('secondary'),
        '&:disabled': {
          border: `1px solid ${getColor('disabledInputBorder')}`,
          backgroundColor: getColor('disabledInput'),
        },
        boxShadow:
          '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        '&:hover': {
          backgroundColor: getColor('primary'),
          boxShadow:
            '0px 3px 1px -2px rgb(0 0 0 / 50%), 0px 2px 2px 0px rgb(0 0 0 / 44%), 0px 1px 5px 0px rgb(0 0 0 / 42%)',
        },
      },
      root: {
        borderRadius: getRadius('inputPrimary'),
      },
      text: {
        color: '#7265e3',
      },
    },
  },
});
