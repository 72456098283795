import Api, { AxiosPromise } from './axioDefault';

async function getDailyReviewRatingsQuery(
  orgId: string,
  timeRange: string,
  userId?: string
): AxiosPromise {
  const body = {
    orgId: orgId,
    timeRange: timeRange + 'd',
    userId: userId,
  };

  return await Api.post(`stats/challenges/v1/review-ratings`, body);
}

export { getDailyReviewRatingsQuery };
