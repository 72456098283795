import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ChallengeDetails } from '../../@models/challengeDetails';
import { getChallengeDetailsQuery } from '../../providers/challenges';
import { LoadingStatus } from '../common';

type GetChallengeDetailsResponse = {
  data: ChallengeDetails;
  meta: any;
};

type GetChallengeDetailsProps = {
  challengeId: string;
};

type GetChallengeDetails = AsyncThunk<
  GetChallengeDetailsResponse,
  GetChallengeDetailsProps,
  any
>;

export const getChallengeDetails = <GetChallengeDetails>(
  createAsyncThunk(
    'challenge-details/getChallengeDetails',
    async (request, { rejectWithValue }) => {
      try {
        const getChallengeDetailsResponse = await getChallengeDetailsQuery(
          request.challengeId
        );

        return getChallengeDetailsResponse;
      } catch (err) {
        console.warn(err);
        return rejectWithValue({ error: err });
      }
    }
  )
);

const initialState = {
  challengeDetails: <ChallengeDetails | null>{},
  status: LoadingStatus.idle,
  error: <string>'',
};

const challengesDetailsSlice = createSlice({
  name: 'challengeDetails',
  initialState,
  reducers: {
    clearChallengeDetails(state) {
      return {
        ...state,
        challengeDetails: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getChallengeDetails.pending, (state) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });

    builder.addCase(getChallengeDetails.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        challengeDetails: { ...action.payload.data },
      };
    });

    builder.addCase(getChallengeDetails.rejected, (state) => {
      // @ts-ignore
      return {
        ...state,
        status: LoadingStatus.failed,
        error: 'Failed to get challenge details.',
      };
    });
  },
});

export const { clearChallengeDetails } = challengesDetailsSlice.actions;
export default challengesDetailsSlice.reducer;
