import Api, { AxiosPromise } from './axioDefault';

async function getChallengesLibraryQuery(
  orgId: string,
  taxonomy?: string
): AxiosPromise {
  const body = {
    orgId: orgId,
    taxonomy: taxonomy,
  };

  return await Api.post(`challenge-library/v1/query`, body);
}

async function cloneChallengeTemplateQuery(
  orgId: string,
  taskSpecId: string
): AxiosPromise {
  const body = {
    orgId: orgId,
    taskSpecId: taskSpecId,
  };

  return await Api.post(`challenge-library/v1/clone-template`, body);
}

export { getChallengesLibraryQuery, cloneChallengeTemplateQuery };
