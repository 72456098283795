import Api, { AxiosPromise } from './axioDefault';

type GetPreSignedUploadUrlRequest = {
  orgId: string;
  importId: string;
};

export async function bulkImportMutation(
  request: GetPreSignedUploadUrlRequest
): AxiosPromise {
  const body = {
    importId: request.importId,
    orgId: request.orgId,
  };

  return await Api.post(`invites/v1/bulk-import`, body);
}
