import { createSlice, createAsyncThunk, AsyncThunk } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';
import { UnregisteredUser, User, VisiblyGroups } from '../../@models/user';
import { registerUserMutation, getUserQuery } from '../../providers/user';
import { removeToken } from '../auth/authSlice';
import { LoadingStatus } from '../common';

// TODO: the types don't check params correctness. Fix!

type RegisterProps = UnregisteredUser & { password: string; inviteId?: string };

type UserResponse = {
  data: User;
  meta: any;
};

type RegisterUser = AsyncThunk<
  UserResponse,
  RegisterProps,
  { rejectValue: { error: string; data: UnregisteredUser } }
>;

type CodeVerifyProps = { username: string; code: string };
type VerifyRegistration = AsyncThunk<boolean, CodeVerifyProps, any>;

type GetUser = AsyncThunk<User, string, { rejectValue: { error: string } }>;

const mapRegisterResponseToUser = <User>(response: any) => {
  if (response) {
    return {
      id: response.id ? response.id : '',
      username: response.handle ? response.handle : '',
      name: response.forenames ? response.forenames : '',
      surname: response.lastName ? response.lastName : '',
      email: response.recoveryEmail ? response.recoveryEmail : '',
      phoneNumber: response.mobileNumber ? response.mobileNumber : '',
      verified: response.verified ? response.verified : '',
      orgs: response.orgs ? response.orgs : <[]>[],
      currentOrgId: '', // So far there is no response with current org, so we
      groups: response.groups ? response.groups : <[]>[],
      createdAt: response.createdAt,
    };
  } else {
    return <User>{};
  }
};

export const registerUser = <RegisterUser>(
  createAsyncThunk(
    'user/setRegistered',
    async (registerParams, { rejectWithValue }) => {
      // Isolating password to avoid passing it to the state
      const { password, ...restUser } = registerParams;

      try {
        const response = await registerUserMutation({ ...restUser, password });
        const result = {
          data: response.data,
          meta: {},
        };

        return result;
      } catch (err: any) {
        console.warn(err);
        const errorMessage =
          err?.errorCode === '409-USERS-EmailOrHandleConflict'
            ? 'Email is already in use'
            : 'Registration Failed';

        return rejectWithValue({
          error: errorMessage,
          data: restUser,
        });
      }
    }
  )
);

export const verifyRegistration = <VerifyRegistration>(
  createAsyncThunk(
    'user/setVerification',
    async ({ username, code }, { rejectWithValue }) => {
      try {
        await Auth.confirmSignUp(username, code);
        return true;
      } catch (err) {
        console.warn(err);
        // TODO: Customize the most major error messages
        return rejectWithValue({
          error: 'Verification failed!',
          data: false,
        });
      }
    }
  )
);

const getUserGroupInfo = async (): Promise<VisiblyGroups[]> => {
  try {
    const session = await Auth.currentSession();
    return session.getIdToken().payload['cognito:groups'];
  } catch {
    //intentionally catch this and return null if we can read the group info,
    //as we do not want to crash the program simply because we cannot read the groups of a user
    return [];
  }
};

export const getUser = <GetUser>(
  createAsyncThunk('user/getUser', async (userId, { rejectWithValue }) => {
    try {
      const response = await getUserQuery(userId);
      const groups = await getUserGroupInfo();
      return { ...response.data, groups: groups };
    } catch (err: any) {
      console.warn(err);
      // TODO: Customize more major error messages
      return rejectWithValue({
        error: err.errorMessage,
      });
    }
  })
);

const initialState = {
  user: <User>{},
  status: LoadingStatus.idle,
  error: <string>'',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setMainInfo: (state, action) => ({
      ...state,
      user: <User>{ ...state.user, ...action.payload },
    }),
    setCredentials: (state, action) => ({
      ...state,
      user: <User>{ ...state.user, ...action.payload },
    }),
    setCurrentOrgId: (state, action) => ({
      ...state,
      user: <User>{ ...state.user, currentOrgId: action.payload },
    }),
    addUserOrg: (state, action) => ({
      ...state,
      user: <User>{ ...state.user, orgs: [...state.user.orgs, action.payload] },
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(registerUser.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(registerUser.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        user: {
          ...state.user,
          ...mapRegisterResponseToUser(action.payload.data),
        },
      };
    });
    builder.addCase(registerUser.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
        error: action.payload?.error ?? 'Register user failed',
        user: { ...state.user, ...action.payload?.data },
      };
    });
    builder.addCase(verifyRegistration.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });
    builder.addCase(verifyRegistration.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        user: {
          ...state.user,
          verified: true,
        },
      };
    });
    builder.addCase(verifyRegistration.rejected, (state) => {
      return {
        ...state,
        status: LoadingStatus.failed,
        user: {
          ...state.user,
          verified: false,
        },
      };
    });
    builder.addCase(getUser.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        user: {
          ...mapRegisterResponseToUser(action.payload),
          currentOrgId: state.user.currentOrgId,
        },
      };
    });
    builder.addCase(getUser.rejected, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.failed,
        error: action.payload?.error ?? 'Failed to get user information',
      };
    });

    builder.addCase(removeToken.type, () => initialState);
  },
});

export const { setMainInfo, setCredentials, setCurrentOrgId, addUserOrg } =
  userSlice.actions;
export default userSlice.reducer;
