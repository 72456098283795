import { CreateTeamRequest, UpdateTeamRequest } from '../@models/team';
import Api, { AxiosPromise } from './axioDefault';

//this is the request definition for the get invites request from backend, but at the moment we are only going to use searchString and orgId;
type GetTeamsRequest = {
  orgId: string;
  name?: string | null;
};

async function getTeamsQuery(request: GetTeamsRequest): AxiosPromise {
  return await Api.post(`/teams/v1/query`, request);
}

async function createTeamMutation(request: CreateTeamRequest): AxiosPromise {
  const body = {
    orgId: request.orgId,
    description: request.description,
    name: request.name,
  };

  return await Api.post(`/teams/v1`, body);
}

async function updateTeamMutation(request: UpdateTeamRequest): AxiosPromise {
  const body = {
    name: request.name,
    description: request.description,
  };
  return await Api.put(`/teams/v1/${request.id}`, body);
}

async function getTeamByIdQuery(id: string): AxiosPromise {
  return await Api.get(`/teams/v1/${id}`);
}

export {
  getTeamsQuery,
  getTeamByIdQuery,
  createTeamMutation,
  updateTeamMutation,
};
