import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Review } from '../../@models/review';
import { queryReviews } from '../../providers/challenges';
import { LoadingStatus } from '../common';

type GetReviewsResponse = {
  data: Review[];
  meta: any;
};

type GetReviewsProps = {
  challengeId: string;
  states: string[];
  reviewerTeamId?: string | null;
  maxReviews?: number | null;
};

type GetReviews = AsyncThunk<GetReviewsResponse, GetReviewsProps, any>;

export const getReviews = <GetReviews>(
  createAsyncThunk(
    'challenge-details/reviews',
    async (request, { rejectWithValue }) => {
      try {
        const queryReviewsResponse = await queryReviews(
          request.challengeId,
          request.states,
          request.reviewerTeamId,
          request.maxReviews
        );

        return queryReviewsResponse;
      } catch (err) {
        console.warn(err);
        return rejectWithValue({
          error: err,
        });
      }
    }
  )
);

const initialState = {
  reviews: <Review[]>[],
  status: LoadingStatus.idle,
  error: <string>'',
};

const reviewsSlice = createSlice({
  name: 'reviews',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getReviews.pending, (state) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });

    builder.addCase(getReviews.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        reviews: [...action.payload.data],
      };
    });

    builder.addCase(getReviews.rejected, (state) => {
      // @ts-ignore
      return {
        ...state,
        status: LoadingStatus.failed,
        error: 'Failed to get challenge reviews.',
      };
    });
  },
});

export default reviewsSlice.reducer;
