import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ChallengeTemplateOverview } from '../../@models/challengeTemplate';
import { cloneChallengeTemplateQuery } from '../../providers/challengesLibrary';
import { LoadingStatus } from '../common';

type CloneChallengeTemplateResponse = ChallengeTemplateOverview;

type CloneChallengeTemplateProps = {
  orgId: string;
  taskSpecId: string;
};

type CloneChallengeTemplate = AsyncThunk<
  CloneChallengeTemplateResponse,
  CloneChallengeTemplateProps,
  any
>;

export const cloneChallengeTemplate = <CloneChallengeTemplate>(
  createAsyncThunk(
    'challenges-library/cloneTemplate',
    async (request, { rejectWithValue }) => {
      try {
        const cloneChallengeTemplate = await cloneChallengeTemplateQuery(
          request.orgId,
          request.taskSpecId
        );

        return cloneChallengeTemplate;
      } catch (err) {
        console.warn(err);
        return rejectWithValue({ error: err });
      }
    }
  )
);

const initialState = {
  challengesLibrary: <ChallengeTemplateOverview>{},
  status: LoadingStatus.idle,
  error: <string>'',
};

const challengesLibraryTemplateSlice = createSlice({
  name: 'challengesLibraryTemplate',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(cloneChallengeTemplate.pending, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.loading,
      };
    });

    builder.addCase(cloneChallengeTemplate.fulfilled, (state, action) => {
      return {
        ...state,
        status: LoadingStatus.succeeded,
        challengesLibraryTemplate: { ...action.payload },
      };
    });

    builder.addCase(cloneChallengeTemplate.rejected, (state, action) => {
      // @ts-ignore
      return {
        ...state,
        status: LoadingStatus.failed,
        error: 'Failed to clone challenge library template',
      };
    });
  },
});

export default challengesLibraryTemplateSlice.reducer;
