import { createSlice, createAsyncThunk, AsyncThunk } from '@reduxjs/toolkit';
import { TaskSpecChallengeActivity } from '../../@models/taskSpecChallengeActivity';
import {
  taskSpecChallengeActivitiesQuery,
  TaskSpecChallengeActivitiesRequest,
} from '../../providers/tasksStats';
import { LoadingStatus } from '../common';

type GetChallengeActivityByTaskSpec = AsyncThunk<
  ChallengeActivityByTaskSpecResponse,
  TaskSpecChallengeActivitiesRequest,
  any
>;

type ChallengeActivityByTaskSpecResponse = {
  data: TaskSpecChallengeActivity[];
  meta: any;
};

export const getTaskSpecChallengeActivities = <GetChallengeActivityByTaskSpec>(
  createAsyncThunk(
    'taskStats/challengeActivityByTaskSpec',
    async (request, { rejectWithValue }) => {
      try {
        return await taskSpecChallengeActivitiesQuery(request);
      } catch (err) {
        console.warn(err);
        return rejectWithValue({ error: err });
      }
    }
  )
);

const initialState = {
  taskSpecChallengeActivities: <TaskSpecChallengeActivity[]>{},
  status: LoadingStatus.idle,
  error: <string>'',
};

const tasksStatsSlice = createSlice({
  name: 'taskStats',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTaskSpecChallengeActivities.pending, (state, action) => {
      return { ...state, status: LoadingStatus.loading };
    });
    builder.addCase(
      getTaskSpecChallengeActivities.fulfilled,
      (state, action) => {
        return {
          ...state,
          status: LoadingStatus.succeeded,
          taskSpecChallengeActivities: action.payload.data,
        };
      }
    );
    builder.addCase(
      getTaskSpecChallengeActivities.rejected,
      (state, action) => {
        return {
          ...state,
          status: LoadingStatus.failed,
          taskSpecChallengeActivities: <TaskSpecChallengeActivity[]>{},
        };
      }
    );
  },
});

export default tasksStatsSlice.reducer;
