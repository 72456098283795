import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ChallengeWithReviewDiscrepancy } from '../../@models/reviewDiscrepancies';
import { getChallengesWithReviewDiscrepanciesQuery } from '../../providers/challengeStats';
import { LoadingStatus } from '../common';

type GetChallengesWithReviewDiscrepanciesProps = {
  orgId: string;
  date: Date;
  teamId?: string;
};

type GetChallengesWithReviewDiscrepanciesResponse = {
  data: ChallengeWithReviewDiscrepancy[];
  meta: {};
};

type GetChallengesWithReviewDiscrepancies = AsyncThunk<
  GetChallengesWithReviewDiscrepanciesResponse,
  GetChallengesWithReviewDiscrepanciesProps,
  any
>;

export const getChallengesWithReviewDiscrepancies = <
  GetChallengesWithReviewDiscrepancies
>createAsyncThunk(
  'stats-challenges/getChallengesWithReviewDiscrepancies',
  async (request, { rejectWithValue }) => {
    try {
      return await getChallengesWithReviewDiscrepanciesQuery(
        request.orgId,
        request.date,
        request.teamId
      );
    } catch (err) {
      console.warn(err);
      return rejectWithValue({ error: err });
    }
  }
);

const initialState = {
  challengesWithReviewDiscrepancies: <ChallengeWithReviewDiscrepancy[]>[],
  status: LoadingStatus.idle,
  error: <string>'',
};

const challengeWithReviewDiscrepancySlice = createSlice({
  name: 'challengeWithReviewDiscrepancy',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getChallengesWithReviewDiscrepancies.pending,
      (state, action) => {
        return {
          ...state,
          status: LoadingStatus.loading,
        };
      }
    );
    builder.addCase(
      getChallengesWithReviewDiscrepancies.fulfilled,
      (state, action) => {
        return {
          ...state,
          status: LoadingStatus.succeeded,
          challengesWithReviewDiscrepancies: [...action.payload.data],
        };
      }
    );

    builder.addCase(
      getChallengesWithReviewDiscrepancies.rejected,
      (state, action) => {
        // @ts-ignore
        return {
          ...state,
          status: LoadingStatus.failed,
          error: 'Failed to get challenges with review discrepancies',
        };
      }
    );
  },
});

export default challengeWithReviewDiscrepancySlice.reducer;
