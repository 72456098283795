import { Route, Redirect, RouteProps } from 'react-router-dom';

type Props = {
  children: any;
  auth?: boolean;
  path: string;
  exact?: boolean;
};

function PrivateRoute({ children, auth, ...rest }: Props) {
  const render = ({ location }: RouteProps) => {
    if (!auth) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location },
          }}
        />
      );
    }
    return children;
  };

  return <Route {...rest} render={render} />;
}

export default PrivateRoute;
